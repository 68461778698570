.col { margin-bottom: 0; padding-bottom: 0; border-bottom: none; }
.col_2 { .ibt; width: 50%; padding-right: 10px; }
.col_2:nth-child(even) { padding-right: 0; padding-left: 10px; }

.shop .col_2:nth-child(2n+3) { margin-top: 5px; }

.c30 { width: 30%; }
.c70 { width: 70%; }

.c40 { width: 40%; }
.c60 { width: 60%; }

//flexbox for order

.att_item, .epp_item {

	.basic_wrap + .basic_wrap { display: block; }

	.choices { margin-top: 0; }

}

.btn50.new_line { .ibt; margin-right: 1%; margin-bottom: 1%; }

.btn50.new_line { width: 49.5%; }
.btn50.new_line + .btn50.new_line { margin-right: 0; }

.extras_list, .no-js .promotions {

	.extras_cols;

}

.promotions {

	.slick-slide {

		p { min-height: 1px; }

	}

}

.col_2 + .test_items { padding-left: (40px + 10px); margin-top: 0;

	&:before, &:after { display: block; content: ""; width: 1px; height: 100%; position: absolute; left: 20px;  }
	&:before { top: 0;}
	&:after { bottom: 0; .gradient-v(rgba(255,255,255,0), #fff, 30px, 100%); background-color: transparent; }

	h1:first-child { padding-left: 0; }

}

.col_2 + .search_items { padding-left: (10px + 10px); }

.enews_social { bottom: -85px;

	> div { .ibt; width: 50%; padding-right: 10px; }
	> * + ul { .ibt; width: 50%; padding-left: 10px; }

	li { margin-top: 0; }

}

.form_line.inline {

	.inline_form;

}

//col_3

.col_3 {

	.item { .ibt; width: 49.5%; margin-right: 1%; }
	.item:nth-child(2n) { margin-right: 0; }

}
//salvattore for blog
.js [data-columns]::before { content: '2 .column.size-1of2'; }

//

//new search bar

.search_bar {

	.search_bar_switch;

}

//

//packages list

.packages_page .tabs a { min-width: 187px; padding-left: 30px; padding-right: 30px; }

.footer_container {

	.footer { padding-top: 105px; }

}

//content

.content {

	h1, .h1 {

		small { .ibm; margin-left: 5px; }

	}

}

//very specific page fixes (via body class)

//about page

.plans_list {

	//rows

	&.rows {

		> div { .gradient-h(#f5f5f5,@color_4); }

		.item { text-align: left; padding-left: 140+50px; padding-bottom: 0; }

		img { width: 140px; max-width: 140px; position: absolute; top: 20px; left: 20px; }

		h1 { padding-top: 3px; padding-bottom: 17px; }
		h1:after { left: 0; margin-left: 0; }

		.btn { position: absolute; top: 20px; right: 20px; left: auto; }

	}

}

.login_page, .contact_page, .dashboard_page {

	.col { padding-right: 30px; }
	.col + .col { padding-right: 0; padding-left: 30px; }

}

.contact_page {

	.col textarea { height: (365px + 10px); }
	.col + .col form { margin-top: -10px; }

}

//admin quick view

.qv_1 {

    small { display: inline-block; margin-top: 0; }

}

//books

.item.book > div { padding-top: 15px; padding-bottom: 90px; padding-left: 170px; }