body.home_page { background-position: 50% 0px; }

.container { width: 1366px; }

//intro

.main > .intro {

	h1,
	p { padding-right: 440px; }

}

.plans_list.plans_3 {

	> div > div { min-height: 409px; }

	.btn { bottom: 20px; width: 49.5%; padding-right: 0; }
	.btn + .btn { padding-left: 0; }
}

//rows
.plans_list.rows { display: flex;

    > div { width: 49.5%; margin-right: 1%; .ibt; margin-bottom: 10px; display: flex; }
    > div:nth-child(2n) { margin-right: 0; margin-bottom: 10px; }

}

.extras_list {

	.item { height: auto; }
    .mta { min-height: 139px; }

}

.js .testimonial_examples blockquote { min-height: 150px; }
.js .testimonial_details blockquote { min-height: 1px; }
.js .home_page .testimonial_examples blockquote { min-height: 195px; }

//maestro

.home_page .maestro { right: 95px; top: 420px; /*.background-size(100%,auto); background-position: 100% 100%;*/  .transform(~"none"); transform-style: flat;

    > * { top: 10px; right: 0; }

}

//holding page adjustments

.holding_page {

    .main > .intro {

        .alert_message { width: 520px; padding: 30px; }

    }

}