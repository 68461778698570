body.home_page { background: url("@{image_base}playing_piano.jpg") no-repeat 50% -15px;

	&:after { .ba; width: 100%; height: 100px; position: absolute; top: 550px; left: 0; background: url("@{image_base}curve.svg") no-repeat 0 0; .background-size(100%,100px); z-index: 1; }

	.no-svg &:after { background-image: url("@{image_base}curve.png"); }

}

.main { padding-bottom: 0; }
body.admin .main,
body.no_nav .main { border-bottom: none; }

.main_container { padding: 60px 60px 60px 350px; }

//ribbon_area

.ribbon_area { width: 260px; position: absolute; top: 0; left: 30px; padding: (60px + 90px) 30px 120px; border-bottom: 105px solid #fff;

	&:after { .ba; width: 260px; height: 60px; position: absolute; bottom: 0; left: 0; background: url("@{image_base}triangle.svg") no-repeat 0 0; }
	.no-svg &:after { background-image: url("@{image_base}triangle.png"); }

}
.no_nav .ribbon_area { padding-top: 60px; }
.home_page .ribbon_area { min-height: 720px; border-bottom: none; }

.btn_login { position: absolute; top: (60px + 100px + 30px + 90px); left: 60px; width: 200px; }

//members_navigation

.members_navigation,
.btn_basket { padding: 10px 0; margin-bottom: 10px; border-bottom: 1px solid lighten(@color_1,10%);  }

.admin .members_navigation { padding-bottom: 0; margin-bottom: 0; border-bottom: none; }

.enews_social { margin-top: (45px + 30px); position: static; padding: 0;

	> div,
	ul { display: block; width: 100%; padding-right: 0; padding-left: 0; }

	li { margin-top: 10px; }

}
.members_navigation + .enews_social,
.btn_basket + .enews_social { margin-top: 0; }

.main .accordion { margin-bottom: 30px; }

.members_navigation ~ .members_navigation {

	.btn { padding-top: 10px; }

}
.subnav { padding-top: 76px; }
.btn_basket ~ .members_navigation,
.subnav ~ .members_navigation,
.subnav ~ .members_navigation .btn { padding-top: 0; }

#account { margin-top: (45px + 22px); }
.btn_basket ~ #account,
.subnav ~ #account { margin-top: 0; }

//nav

.btn_menu, .btn_account, .btn_sub { display: none; }

.navigation { background-color: transparent; padding: 0px;

	li .ico:before { top: auto; bottom: 13px; }

}

.btn_login,
.btn_logout { margin-bottom: 10px; padding-bottom: 10px; }
.btn_basket { position: static; width: 100%; margin-top: (45px + 22px);

	.number { position: static; display: inline; background-color: transparent; .font-size(1.4); .line-height(2.5); min-width: 1px; padding: 0; .rounded(0); top: auto; right: auto; color: #fff; }
	.text, .price { display: inline; }
	.ico:before { width: auto; margin-right: 3px; }

}
.members_navigation + .btn_basket { margin-top: 0; padding-top: 0; }

.navigation .tier_1 { position: absolute; top: 30px; left: 0; z-index: 4; width: 100%; text-align: left;

	// > li.home_btn { width: 5%; }
	> li { .ibt; padding-left: 1px; padding-top: 0; width: 16.66666666666667%; margin-top: 0; //16.66666666666667 15.83333333333333

		> a { height: 60px; }

		&:first-child { padding-left: 0; }

	}

	small { display: block; margin-top: -7px; }

	.ico:before { .font-size(1); position: absolute; top: auto; bottom: 13px; right: 15px; width: auto; height: auto; }

	.tier_inner { position: absolute; left: -9999em; top: -9999em; .opacity(0); .transition(opacity); }

	> li:hover,
	> li.hover {

		.tier_inner { left: 1px; top: 60px; .opacity(1); }
		&:first-child .tier_inner { left: 0; }

	}
	> li:last-child:hover,
	> li.hover:last-child {

		.tier_inner { left: auto; right: 0; }

	}

}
.n5 .tier_1 > li { width: 20%; }
.n4 .tier_1 > li { width: 25%; }
.n3 .tier_1 > li { width: 33.33333333333333%; }

.navigation .tier_inner { width: 150%; .box-shadow(0,7.5px,5px,-5px,rgba(0,0,0,.1));

	li:first-child a,
	li:first-child .no_link { border-top: none; }

}
.n3 .tier_inner { width: 100%; padding-right: 1px; }
.n3 li:first-child .tier_inner { padding-right: 0; }

.orange_nav;

.home_page {

	.white_nav;

}

//content

.c40 { width: 100%; padding-right: 0; border-bottom: 1px solid #ddd; margin-bottom: 30px; padding-bottom: 30px; }
.c40:last-child { border-bottom: none; margin-bottom: 0; padding-bottom: 30px; }
.c60 { width: 100%; }

//flexbox for order

.att_item, .epp_item {

	.basic_wrap + .basic_wrap { display: flex; }

	.choices { margin-top: calc(~"20px + 0px"); }

}

hr { margin: 30px 0; }

.space_stuff, .trumbowyg-editor {

	> * { margin-bottom: 30px; }
	> *:last-child { margin-bottom: 0; }

}
.trumbowyg-editor > *:last-child { margin-bottom: 30px; }
.main > .basic_wrap { padding: 0; margin: 30px 0; }
.main > *:first-child { padding: 90px 0 0 0; margin-top: 0; }
.ribbon_area + .main > *:first-child { padding-top: 0; }
.main > .content { padding-left: 0; padding-right: 0; }

.space_stuff + .space_stuff { margin-top: 30px; }
.tabs ~ .space_stuff { margin-top: 0; }

.main > *:last-child { margin-bottom: 0; }

.tab_set .content { padding-top: 30px; }

.form_line.inline { margin: 0;

	> label { margin: @pad 0; background: none; line-height: inherit; margin-right: 0; }
	&:first-child > label { margin-top: 0; }
	> label, .input { display: block; width: 100%; }
	.input { margin-left: 0; }
	> label { text-align: left; padding-right: 0; }
	.note { margin-left: 0; }

	.check_radio {

		li { margin-bottom: 1%; }
		label { padding-top: 5px; padding-bottom: 5px; }

	}

}

//intro

.main > .intro { min-height: 560px; background: none; text-align: left;

	.btn { width: 110px; margin-right: 2px; margin-top: 15px; }

	h1, p { max-width: 999em; }

	h1 span,
	p span { display: inline; }

}

//maestro

.home_page .enews_social { padding-right: 0px; }

.home_page .maestro { bottom: auto; top: 440px; right: 20px; /*.background-size(80%,auto); background-position: 60% 100%;*/ .transform(scale(.8)); transform-style: preserve-3d;

	> * { right: 0; top: 68px; }

	&:before { .ba; position: absolute; top: 50%; left: 50%; margin-left: -250px; margin-top: -250px; width: 500px; height: 500px; .gradient-r(#fff,rgba(255,255,255,0),0%,60%); z-index: -1; .opacity(.75);  .transform(translateZ(-1em)); pointer-events: none; }

    .no-svg &:before { background-image: url("@{image_base}circle.png"); }

}

//plans list

.plans_list, .plans_list.plans_3 { margin-top: 60px; padding: 0;

	> div { display: block; width: 100%; margin-right: 0;

		> div { min-height: 1px; padding: 20px; }

	}

	.btn { bottom: 20px; width: 49.5%; padding-left: 20px; padding-right: 0; }
	.btn + .btn { padding-right: 20px; padding-left: 0; bottom: 20px; }
	ul + .btn_primary,
	h3 ~ .btn,
	.set .btn { padding-right: 0; }

	//rows

	&.rows { margin-top: 30px;

		.item { padding-left: (100px + 50px); }

		img { width: 100px; max-width: 100px;}

		.btn { top: 135px; right: auto; left: 20px; width: 100px; }

	}

}
.plans_list.plans_3 > div > div { padding-bottom: 85px; }
//holding
.plans_list.plans_3 .soon .btn { width: 100%; padding-right: 20px; bottom: 20px; }

.packs {

    .item .has_pics {

        h1 { margin-top: 15px; }
        h1, .info { padding-left: 0px; }
        // .thumb { position: static; }

        .media, .media + * { width: 100%; }

    }

}

.extras_list, .promotions, .no-js .promotions  { padding: 0; border-bottom: none; margin-top: 0; }
.subscription_plans .extras_list { padding-bottom: 0px; }

.extras_list, .no-js .promotions { .extras_rows; }

.promotions {

	.col & { margin-bottom: 30px; }
	.col &:last-child { margin-bottom: 0; }

	.item > a,
	.item > .nolink { padding: 30px; }
	.slick-slide {

		a { padding-bottom: 52.5px; }

	}

	&.slider { padding-bottom: 22.5px; }

}

.plans_list + .extras_list,
.extras_list + .promotions { margin-top: 30px; }

.home_page .promotions { margin-bottom: 0; }

//packages list

.packages_page {

	.plans_list { margin-top: 30px;

		.packages_1;

	}
	.alert_message { margin-top: 30px; }

}

//home page testimonials

.testimonial_examples { padding: 30px 20px 0;

	blockquote, .no-js & .item:first-child blockquote { padding-top: 30px; }
	&.testimonial_details blockquote { padding-top: 0; }

}
.home_page .testimonial_examples { position: absolute; left: 30px; top: 733px; width: 260px; padding: 30px; }

.testimonials_more { top: 0; }

.testimonial_details { padding-left: 0; padding-right: 0; }

//aural

.col_2 + .test_items { padding-left: 0;

	> *,
	.set > *,
	.question_wrapper > *,
	.tab_set > * { margin-bottom: 30px; }
	> *:last-child,
	.set > *:last-child,
	.question_wrapper > *:last-child,
	.tab_set > *:last-child//,
	//.tabs
	{ margin-bottom: 0; }

	> .note, .set .note, .question_wrapper .note  { margin-top: -25px; }

	&:before, &:after { display: none; }

	h1:first-child { padding-left: (40px + 10px); }

	.sr_rf { padding-bottom: 0; }

}
.col_2 + .search_items { padding-left: 0; }
//guide
.guide { margin-bottom: 30px; }
.promotions + .guide { margin-top: -25px; }

//col_3

.col_3 {

	.item { width: 100%; margin-right: 0%; }

}
//salvattore for blog
.js [data-columns]::before { content: '1 .column.size-1of1'; }

//blog

.blog_details > img { float: none; margin-right: 0; }

//new search bar

.search_bar {

	.pages { width: 100%; padding-right: 0px; display: block; }
	.sort_show { width: 100%; padding-left: 0px; position: static; margin-top: 10px; }

	&.search_bar--1-page {

		.filter { padding-top: 0; }

	}

}

//

//advert

.advert hr { display: block; }
.js .advert { position: absolute; top: 0; left: (30px + 30px);

	hr { display: none; }

}

//modal

.modal {

	.m_inner { padding: 30px 80px; }
	.m_wide { padding-left: 30px; padding-right: 30px; }

}

//footer

.footer_container { padding-left: 320px; padding-bottom: 104px;

	.footer,
	body.admin & .footer { border-top: 1px solid #ccc; padding: 60px 30px 0; }

}

.home_page {

	.footer_realign;

}

//very specific page fixes (via body class)

.contact_page, .login_page, .dashboard_page {

	.col { width: 100%; padding-right: 0; }
	.col + .col { padding-left: 0; margin-top: 40px; }

}

//about

.content p.w50 { width: 100%; }

//holding page adjustments

.holding_page {

	.main { padding-bottom: 0; }
	.main > *:first-child { padding-top: 0; }

	.ribbon_area { padding-top: 60px; }
	.btn_login { top: 280-90px; }
	.enews_social ul { width: auto; }

	.main > .intro {

		.alert_message { max-width: 100%; text-align: left; }

	}

	.maestro { top: 560px; z-index: 1; }

	.footer_container {

		.accepted_payments { top: 70px; }

	}

}