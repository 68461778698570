@import "_classes";

@color_error: #f00; // red
@color_warning: #fc0; // yellow
@color_notice: #39c; // blue
@color_success: #090; // green

@twitter: #01ACED; // blue
@facebook: #3D5B98; // blue
@linkedin: #666; // grey
@google: #DC0401; // red
@youtube: #942422; // red
@vimeo: #172322; // grey
@dribbble: #EA4C89; // pink
@tumblr: #34465D; // blue

@font-size: 16;
@line-height: 24;

//@inputs: ~'input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="tel"]';

@image_base: "../img/";
@font_base: "../fonts/";

.font_sans() { font-family: Arial, Helvetica, sans-serif; }
.font_serif() { font-family: Georgia, "Times New Roman", Times, serif; }
.font_debug() { font-family: "Courier New", Courier, monospace; }

.icon() { font-family: "icons"; speak: none; }

.ibt() { display: inline-block; vertical-align: top; *display: inline; }
.ibm() { display: inline-block; vertical-align: middle; *display: inline; }
.ibb() { display: inline-block; vertical-align: bottom; *display: inline; }
.img() { display: block; width: 100%; height: auto; }

.notext() { text-indent: 100%; white-space: nowrap; overflow: hidden; display: inline-block; width: 0; }

.pos() { position: absolute; top: -9999px; left: -9999px; }
.nopos() { position: static; top: auto; left: auto; }

.ba() { display: block; content:""; }

.middle() { margin-left: auto; margin-right: auto; }

//specific

@color_1: #222; // grey
@color_2: #E78705; // orange
@color_3: #005677; // blue
@color_4: #D3C7C4; // beige

@abrsm: #D5190D; //red
@trinity: #58174D; //purple

.osr() { font-family: "osr", Arial, Helvetica, sans-serif; }
.ossb() { font-family: "ossb", Arial, Helvetica, sans-serif; }
.osi() { font-family: "osi", Arial, Helvetica, sans-serif; }
.ossbi() { font-family: "ossbi", Arial, Helvetica, sans-serif; }
.osl() { font-family: "osl", Arial, Helvetica, sans-serif; }
.cti() { font-family: "cti", Georgia, Times, serif; }
.ctsbi() { font-family: "ctsbi", Georgia, Times, serif; }

@padding: 30px;
@height: 60px;

@pad: 10px;

.circle() { width: 30px; height: 30px; line-height: 30px; .rounded(15px); }

.ico_circle() { .circle; background-color: rgba(255,255,255,.25); margin-right: 5px; margin-left: -8px; margin-top: -5px; }

.plans_cols() {

	> div { .ibt; width: 33%; margin-right: .5%;

		> div { min-height: 450px; }

	}
	> div:last-child { margin-right: 0; }

	.btn { display: block; width: 100%; padding-right: 15px; bottom: 61px; }
	.btn + .btn { padding-left: 15px; bottom: 15px; }

}

.extras_cols() { display: flex; flex-wrap: wrap;

	.item { .ibt; width: 49.5%; margin-right: 1%; margin-bottom: 5px; }
	.item:nth-child(2n) { margin-right: 0; }

	/*.lighter, */.item:only-child, .subscription_plans & .item { display: block; width: 100%; margin-bottom: 0; margin-right: 0; }
	.item:first-child:nth-last-child(2) { margin-bottom: 0; }
	&.extras_list .xmas { padding: 0; }

	&.promotions {

		.item:last-child, .item:nth-last-child(2) { margin-bottom: 0; }

	}

}

.extras_rows() {

	.item { width: 100%; margin-right: 0; margin-bottom: 1px; display: block; }
	&.extras_list .item { padding: 30px; }
	&.extras_list .xmas { padding: 30px 0 0; }

}

.white_nav() {

	.tier_1 {

		> li > a, > li > .no_link { .gradient-v(#fff,#ccc); background-color: #fff; color: @color_1; }
		small, .ico:before { .opacity(.5); }

	}

	.tier_inner {

		li > a, .no_link { background-color: #fff; border-top: 1px solid lighten(@color_1,50%); color: @color_1; }
		li > a:hover { color: lighten(@color_1,50%); }

		.lighter > a { border-top-color: rgba(0,0,0,.15); border-top-style: dotted; }

	}

}

.orange_nav() {

	.navigation .tier_1 {

		> li > a { .gradient-v(@color_2,darken(@color_2,5%)); background-color: @color_2; color: #fff; }
		small, .ico:before { .opacity(1); }

	}

	.navigation .tier_inner {

		a, .no_link  { border-top: 1px solid lighten(@color_2,20%); background-color: @color_2; color: #fff; }
		a:hover { color: lighten(@color_2,30%); }

		.lighter > a { border-top-color: rgba(255,255,255,.3); border-top-style: dotted; }

	}

}

.grey_nav() {

	.navigation .tier_1 {

		> li > a { .gradient-v(lighten(@color_1,30%),lighten(@color_1,10%)); background-color: lighten(@color_1,30%); color: #fff; }
		small, .ico:before { .opacity(1); }

	}

	.navigation .tier_inner {

		a, .no_link  { border-top: 1px solid darken(#fff,50%); background-color: lighten(@color_1,30%); color: #fff; }
		a:hover { color: darken(#fff,20%); }

	}

}

.abrsm_nav() {

	.tier_1 {

		> li > a, > li > .no_link { .gradient-v(@abrsm,darken(@abrsm,20%)); background-color: @abrsm; color: #fff; }

	}

	.tier_inner {

		li > a, .no_link { background-color: #ddd; }
		.secondary > a { background-color: #f6f6f6; }

	}

}

.trinity_nav() {

	.tier_1 {

		> li > a, > li > .no_link { .gradient-v(@trinity,darken(@trinity,20%)); background-color: @trinity; color: #fff; }

	}

	.tier_inner {

		li > a, .no_link { background-color: #ddd; }
		.secondary > a { background-color: #f6f6f6; }

	}

}

.combined_nav() {

	.tier_1 {

		> li > a, > li > .no_link { .gradient-v(lighten(@color_1,30%),lighten(@color_1,10%)); background-color: lighten(@color_1,30%); color: #fff; }

	}

	.tier_inner {

		li > a, .no_link { background-color: #ddd; }
		.secondary > a { background-color: #f6f6f6; }

	}

}

.inline_form() { margin: @pad 0;

	> label, .input { .ibm; width: 49.5%; }
	> label { padding-right: @pad; margin-top: 0; margin-bottom: 0; .gradient-h(#fff,lighten(@color_4,15%)); .line-height(3.9); margin-right: .5%; }
	&.ta > label { vertical-align: top; margin-top: 3px; }
	&.tick > label { width: 100%; margin-right: 0; text-align: left; }
	.input { margin-left: .5%; }
	.note, &#faq-show { margin-left: 50%; padding-left: .5%; }
	&#faq-show > * { padding: 0 10px; }

	.check_radio {

		li { margin-bottom: 0; }
		label { padding-top: 7px; padding-bottom: 7px; }

	}
	& + .btn:last-child { width: 49.5%; margin-left: 50.5%; }

}

.board_label() { color: #fff; padding: 0 4px; .rounded(3px); }

// .make_table() { display: table; width: 100%;

// 	> *,
// 	> *.inline { display: table-row;

// 		label, .input, .note { display: table-cell; vertical-align: middle; padding-top: 5px; padding-bottom: 5px; width: auto; }
// 		label { text-align: right; padding-right: @pad; }
// 		.note { padding-left: @pad; }

// 	}

// 	hr { display: none; }
// 	hr + .form_line > * { border-top: 1px solid #eee; }

// 	table { width: 100%; }

// }

.packs_3_0() { padding-bottom: (15px + 45px + 10px);

	.price,
	.btn,
	.price + .btn,
	.btn + .btn,
	.qty + .btn,
	.qty { width: 33.333333%; bottom: 15px; }

	.price + .btn,
	.qty { right: auto; left: 33.333333%; padding-right: 7.5px; padding-left: 7.5px; }

	.btn + .btn,
	.qty + .btn { padding-left: 0; }

}

.packs_2_1() { padding-bottom: (15px + 45px + 45px + 10px + 2px);

	.price, .price + .btn, .qty { width: 49.5%; bottom: (15px + 45px + 2px); }

	.price + .btn, .qty { right: 0; left: auto; padding-left: 0; padding-right: 15px; }

	.btn + .btn, .qty + .btn { width: 100%; padding-left: 15px; }

}

.packs_for_select3() {

	li { .ibt; width: 49.5%; margin-right: 1%; margin-bottom: 1%; }
    li:nth-child(even) { margin-right: 0; }
    li:last-child { margin-bottom: 0; }

}

.or() { display: block; content:"or"; position: absolute; top: auto; bottom: -28px; left: 50%; margin-left: -23px; width: 46px; line-height: 38px; background-color: @color_4; color: #fff; text-align: center; text-transform: uppercase; .ossb; .rounded(100%); z-index: 1; border: 4px solid #fff; pointer-events: none; }

.packages_1() {

	> div { display: block; width: 100%; }
	&.has_or > div { position: relative; }
	&.has_or > div:after { .or; }

    > div { margin-bottom: 10px !important; }

	> div:last-child { margin-bottom: 0;

		&:after { display: none; }

	}

}

.packages_2() {

	> div { .ibt; width: 49.5%; margin-right: 1%; margin-bottom: 1% !important; }
    > div:nth-child(2n) { margin-right: 0; }
    &.has_or > div { margin-bottom: 1%; }

    > div:last-child,
    > div:nth-last-child(2),
    > div:nth-last-child(2) ~ div { margin-bottom: 0 !important; }

    &.has_or > div:after { bottom: auto; top: 50%; margin-top: -20px; left: 100%; margin-left: -19px; margin-left: calc(~"1% - 23px"); }

    &.has_or > div:nth-child(2n):after { display: none; }

    &.has_or > div:nth-child(2n+3):before,
    &.has_or > div:nth-child(2n+4):before { .or; bottom: auto; top: -23px; margin-top: -1%; }

}

.footer_realign() {

	.footer_container { text-align: left;

		.footer_links, .accepted_payments { margin-bottom: 0px; }
		.footer_links { position: absolute; right: 60px; top: 60px; }
		.accepted_payments { position: absolute; right: 60px; top: 105px; }

	}

}

.fade_line(@color: #ddd, @height: 1px) { position: relative;

	&:before, &:after { display: block; content:""; height: @height; width: 50%; position: absolute; bottom: 0; z-index: 1; }
	&:before { .gradient-h(fade(@color,0),@color,10%,100%); background-color: transparent; left: 0; }
	&:after { .gradient-h(@color,fade(@color,0),0%,90%); background-color: transparent; right: 0; }

}

.guide_reset() {

	a {  padding: 130px 20px 20px; text-align: center; }

	&:before,
	&:after { top: -30px; right: auto; left: 50%; margin-left: -100px; margin-top: 0; }
	&:after { height: 200px; padding-top: 0; padding-left: 99px; }

}

.guide_adjust() {

	a { padding: 20px 200px 20px 25px; text-align: left; }

	&:before,
	&:after { top: 50%; left: auto; right: 0; margin-left: 0px; margin-top: -75px; }
	&:after { height: 150px; padding-top: 85px; padding-left: 84px; }

}

.search_bar_switch() {

	.pages { width: 40%; padding-right: 5px; .ibt; }
	.sort_show { width: 60%; padding-left: 5px; position: absolute; top: 0; right: 0; margin-top: 0; z-index: 4; }
	&.search_bar--1-page {

		.sort_show { width: 100%; padding-left: 0; }
		.filter { padding-top: 55px; }

	}

}