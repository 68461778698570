//maestro

.home_page .main { padding-bottom: 20px; }
.home_page .enews_social { padding-right: 300px; }

.home_page .maestro { left: auto; margin-left: 0; bottom: -148px; right: 25px; }

//holding page adjustments

.holding_page {

    .enews_social { padding-right: 20px; }
    .enews_social ul { width: 189px; margin-left: auto; margin-right: auto; }

}

//content

.mobile_hide { display: block; }
td.mobile_hide,
th.mobile_hide { display: table-cell; }

.mobile_show { display: none; }
td.mobile_show,
th.mobile_show { display: none; }

.packs {

    .item .has_pics {

        h1 { margin-top: 0; }
        h1, .info { padding-left: 15px; }
        // .thumb { position: absolute; top: -18px; left: -4px; z-index: 1; .transform(rotate(-7.5deg)); .box-shadow(1px,1px,5px,0,rgba(0,0,0,.05)); }

        .media, .media + * { .ibt; width: 50%; }
        .media { width: 145px; }
        .media + * { width: calc(~"100% - 145px"); }

    }

}

//

#cookies_modal { width: 360px; }