@import "_reset";

@import "_variables";

@import "_global";

//min

@media only screen and (min-width:500px) {

	@import "_min500";

}

@media only screen and (min-width:600px) {

	@import "_min600";

}

@media only screen and (min-width:700px) {

    @import "_min700";

}

@media only screen and (min-width:800px) {

	@import "_min800"; //switches from 1 main column to 2 (more padding etc)

}

@media only screen and (min-width:900px) {

	@import "_min900";

}

@media only screen and (min-width:1000px) {

	@import "_min1000";

}

@media only screen and (min-width:1100px) {

	@import "_min1100";

}

@media only screen and (min-width:1200px) {

	@import "_min1200"; //navigation drops to right

}

@media only screen and (min-width:1366px) {

	@import "_min1366";

}

//salvattore max and min/max

@media only screen and (max-width:499px) {

	.js [data-columns]::before { content: '1 .column.size-1of1'; }

}

@media only screen and (min-width:500px) and (max-width:799px) {

	.js [data-columns]::before { content: '2 .column.size-1of2'; }

}

@media only screen and (min-width:800px) and (max-width:999px) {

	.js [data-columns]::before { content: '1 .column.size-1of1'; }

}

@media only screen and (min-width:1000px) and (max-width:1199px) {

	.js [data-columns]::before { content: '2 .column.size-1of2'; }

}

//retina

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
screen and (min-device-pixel-ratio: 1.5) {

	@import "_retina";

}