body.home_page { background: url("@{image_base}playing_piano.png") no-repeat 42% 0px; }

.form_line.inline {

	.inline_form;

}

.col_2.extra_space { padding-right: 30px; }
.col_2.extra_space + .col_2.extra_space { padding-right: 0; padding-left: 30px; }

//intro

.main > .intro {

	h1 { padding-right: 160px; }
	p { padding-right: 110px; }

	.btn { width: 200px; }

}

.plans_list.plans_3 {

	.plans_cols;

	> div > div { min-height: 460px; padding-bottom: 0; }

	.btn { padding-right: 20px; bottom: 66px; }
	.btn + .btn { padding-left: 20px; bottom: 20px; }

}

//maestro

.home_page .maestro { /*.background-size(90%,auto);*/ right: 25px; z-index: auto;

    > * { top: 0px; right: -10px; }

}

//col_3

.col_3 {

    .item { .ibt; width: 49.5%; margin-right: 1%; }
    .item:nth-child(2n) { margin-right: 0; }

}
//salvattore for blog
.js [data-columns]::before { content: '2 .column.size-1of2'; }

//packages list

.packages_page {

    .plans_2 {

        .packages_2;

    }

}

//testimonials page
.testimonial_details {

	// blockquote { .font-size(2.6); .line-height(3.7); }

}

//blog

.blog_details > img { float: left; margin-right: 60px; }

//very specific page fixes (via body class)

.login_page, .contact_page, .dashboard_page {

    .col { width: 50%; padding-right: 30px; }
    .col + .col { padding-right: 0; padding-left: 30px; margin-top: 0; }

}

//about

.content p.w50 { width: 50%; }

//

//new search bar

.search_bar {

    .search_bar_switch;

}

//

//holding page adjustments

.holding_page {

    .main > .intro {

        .alert_message { width: 63%; margin-left: -20px; margin-right: 0; margin-top: 40px; }
        .alert_message p { padding-right: 0; }

    }

    .maestro { top: 420px; }

}