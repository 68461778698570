//ribbon_area

.ribbon_area { padding-top: 60px; }

.btn_login { top: (60px + 100px + 30px); }

//nav

.navigation .tier_1 { position: relative; margin-bottom: 60px; padding-left: 0; padding-right: 0; top: 0; }

//content

.main > .content,
.main > *:first-child { padding: 0; }

// .table_it {

// 	.make_table;

// }

//intro

.main > .intro { padding-top: 0px; min-height: 455px;

	h1 { padding-right: 300px; }
	p { padding-right: 220px; }

}

//maestro

.home_page .maestro { right: 6%;

    // > div { top: 10px; right: 0; }

}

//home page plans etc

.plans_list, .extras_list { .ibt; }

.plans_list.plans_3,
.plans_list.plans_3 + .extras_list { margin-top: 45px; }
.main > .plans_list:first-child { margin-top: 0; padding-top: 0; }

.plans_list,
.plans_list.plans_3,
.subscription_plans .plans_list { width: 71%; padding: 0 30px 0 0; }

//rows
.plans_list.rows { width: 100%; padding-right: 0; }

.plans_list.plans_3 > div > div { min-height: 490px; }

.packs {

    .item .has_pics {

        h1 { margin-top: 0px; }
        h1, .info { padding-left: 15px; }
        // .thumb { position: absolute; }

        .media, .media + * { .ibt; width: 50%; }
        .media { width: 145px; }
        .media + * { width: calc(~"100% - 145px"); }

    }

}

.extras_list { width: 29%; margin-bottom: 0;

	.extras_rows;

	.item { height: 166px; }

}
.subscription_plans .extras_list { margin-top: 0;

	.item { height: auto; }

}
.promotions {

	.slick-slide {

		.h1, p { .ibm; }
		.h1 { margin-bottom: 0; margin-right: 10px; }
		p { .font-size(1.2); }

	}

	//no-js
	.no-js & { padding-bottom: 20px; }

}

.player {

	.vol_btn { margin-right: 1%; }
	.time { width: 10%; }
	.time_track { width: 36%; }
	.volume_track { .ibm; }

}

//search

.show_order { margin-top: -5px; width: 48%; .ibt; margin-right: 2%; }
.only_one { width: 100%; margin-right: 0; }
.show_order + .show_order { margin-right: 0; margin-left: 2%; }

//col_3

.col_3 {

    .item { .ibt; width: 32.66666666666667%; margin-right: 1%; }
    .item:nth-child(n) { margin-right: 1%; }
    .item:nth-child(3n) { margin-right: 0; }
    .item:nth-child(3n+1):nth-last-child(-n+3),
    .item:nth-child(3n+1):nth-last-child(-n+3) ~ .item { margin-bottom: 0; }

}
//salvattore for blog
.js [data-columns]::before { content: '3 .column.size-1of3'; }

//

//new search bar

.search_bar {

    //pages

    .pages { width: 25%; }

    //filter

    .filter { width: 37%; .ibt; margin-top: 0; }

    //sort show

    .sort_show { position: static; width: 38%; padding-left: 10px; .ibt; }

    &.search_bar--1-page {

        .pages { display: none; }
        .filter { width: 50%; padding-top: 0; }
        .sort_show { width: 50%; padding-left: 10px; }

    }

}

//

//admin quick view

.qv_8 {

    small { display: inline-block; margin-top: 0; }

}

//guide

.extras_list, .promotions {

    .item + .guide { .guide_reset(); }

}

//holding page adjustments

.holding_page {

	.main > .intro { min-height: (455px + 120px); }

    .extras_list .item { height: 150px; }

}

//books

.item.book > div { .background-size(250px,190px); padding-bottom: 70px; padding-left: 15px; background-position: 98% 10px;

    .btn:last-child { .opacity(.9); }
    br { display: none; }

}