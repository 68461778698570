//home page plans etc

.plans_list.plans_3 {

	.plans_cols;

    //holding
    .soon .btn { bottom: 15px; }

}

.col_2 + .search_items { padding-left: (40px + 10px); }

//packages list

.packages_page {

    .plans_2 {

        .packages_2;

    }

}

//admin quick view

.qv_8 {

    small { display: inline-block; margin-top: 0; }

}

//blog

.blog_details > img { float: left; margin-right: 30px; }

//guide

.extras_list, .promotions {

    .item + .guide { .guide_adjust(); }

}

//about

.content p.w50 { width: 50%; }