.content {

	h1 { .font-size(3.6); .line-height(4.5); }

}

.c40 { width: 40%; padding-right: 10px; margin-bottom: 0; padding-bottom: 0; border-bottom: none; }
.c60 { width: 60%; }

//flexbox for order

.att_item, .epp_item {

	.basic_wrap + .basic_wrap { display: block; }

	.choices { margin-top: 0; }

}

.extras_list, .no-js .promotions {

	.extras_cols;

}

@pos: 53px;

.promotions { padding-bottom: 0;

	&.slider { padding-left: @pos; padding-right: @pos; padding-bottom: 0; }
	.btn { bottom: auto; top: 50%; margin-top: -22.5px; left: -@pos; margin-left: 0; }
	.btn + .btn { left: auto; right: -@pos; margin-right: 0; }

	//slick
	.slick-slide {

		a { padding-bottom: 30px; }

	}

}

//maestro

.home_page .maestro { right: 80px;

	> * { right: -15px; top: 10px }

}

//aural

.col_2 + .test_items { margin-top: 0; padding-left: (40px + 10px);

	&:before, &:after { display: block; }

	h1:first-child { padding-left: 0; }

	// h1 + .toc { margin-top: -20px; }
	// h1 + form { margin-top: -32px; }

}

.col_2 + .search_items { padding-left: (10px + 10px); }

//about page

.plans_list {

	//rows

	&.rows {

		.item { padding-left: (140px + 50px); }

		img { width: 140px; max-width: 140px; }

		.btn { top: 20px; right: 20px; left: auto; width: auto; }

	}

}

//holding page adjustments

.holding_page {

	.maestro { top: 540px; }

}