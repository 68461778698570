//Looksee, I do LESS, with MORE
.answer_missing {
    color: orangered;
}

@font-face {
	.fontface-svg(icons,icons);
}
@font-face {
	.fontface(osr,OpenSans-Regular-webfont);
}
@font-face {
	.fontface(ossb,OpenSans-Semibold-webfont,600);
}
@font-face {
	.fontface(osi,OpenSans-Italic-webfont);
}
@font-face {
	.fontface(ossbi,OpenSans-SemiboldItalic-webfont,600);
}
@font-face {
	.fontface(osl,OpenSans-Light-webfont,300);
}
@font-face {
	.fontface(cti,crimsontext-italic-webfont);
}
@font-face {
	.fontface(ctsbi,crimsontext-semibolditalic-webfont,600);
}

@media only screen and (max-device-width: 960px) { html { -webkit-text-size-adjust:none; } }

html { font-size: 62.5%; min-width: 320px; height: 100%; overflow-x: hidden; }

body { height: 100%; .font-size(1.4); .line-height(2.5); color: @color_1; .osr; }

.trumbowyg-editor {

	p span,
	li span,
	h5 span,
	h6 span { .font-size(1.4); .line-height(2.5); }
	h1 span { .font-size(3); .line-height(4.1); }
	h2 span { .font-size(2); .line-height(3.8); }
	h3 span { .font-size(2.6); .line-height(3.7); }
	h4 span { .font-size(1.6); .line-height(3.4); }

}//fixes chrome adding span tags in trumbowyg

//loading

.loading { overflow: hidden;

	&:before { background: #fff; background: rgba(255,255,255,.95); content: ""; display: block; height: 100%; left: 0; position: fixed; top: 0; width: 100%; z-index: 9998; }

	&:after { width: 40px; height: 40px; content: ""; display: block; position: fixed; top: 50%; left: 50%; margin-top: -20px; margin-left: -20px; background-color: @color_2; border-radius: 100%; z-index: 9999; -webkit-animation: scaleout 1s infinite ease-in-out; animation: scaleout 1s infinite ease-in-out; }

}

@-webkit-keyframes scaleout {

	0% { -webkit-transform: scale(0); }
	100% { -webkit-transform: scale(1); opacity: 0; }

}

@keyframes scaleout {

	0% { transform: scale(0); -webkit-transform: scale(0); }
	100% { transform: scale(1); -webkit-transform: scale(1); opacity: 0; }

}

caption { display: none; }

input:invalid, textarea:invalid { .rounded(0); .box-shadow(0, 0, 0, 0, transparent); }

button::-moz-focus-inner { border: 0; padding: 0; }

a { .transition(color); color: @color_1; }

*:focus { outline: none; }
button { outline: none; }

hr { border: none; .fade_line(); margin: 20px 0; }
.search_items > hr:last-child { display: none; }

#subnav, #account {

	li hr { margin: 10px 0; .opacity(.5); }

}

*, *:before, *:after { .box-sizing(); }

::-moz-selection{ background: @color_2; color: #fff; }
::selection { background: @color_2; color: #fff; }

.ico:before { .icon; .ibm; text-align: center; width: 100%; height: 100%; .font-size(1.6); line-height: 18px; }
.ico_inline:before { .font-size(1); width: auto; margin-left: 2.5px; .opacity(.5); margin-top: -2px; }

@import "_icons";

.hide, #id { display: none; }
.clearfix:after { content: ""; display: table; clear: both; }

.disabled,
.inactive,
.uneditable { .opacity(.25); cursor: default; }
.inactive { .transition(opacity); .backface(); }
.inactive:hover { .opacity(1); }
*[disabled] { cursor: default; }
div.disabled { pointer-events: none; }

.o_10 { .opacity(.1); }
.o_20 { .opacity(.2); }
.o_30 { .opacity(.3); }
.o_40 { .opacity(.4); }
.o_50 { .opacity(.5); }
.o_60 { .opacity(.6); }
.o_70 { .opacity(.7); }
.o_80 { .opacity(.8); }
.o_90 { .opacity(.9); }
.o_100 { .opacity(1); }

.left { text-align: left; }
.center { text-align: center; }
.middle { vertical-align: middle; }
.vatop { vertical-align: top; }
.right { text-align: right; }

.ir { display: inline-block; text-indent: -9999px; overflow: hidden; background-repeat: no-repeat; text-align: left; direction: ltr; }
.irp { position: absolute; left: -9999px; overflow: hidden; font-size: 0px; line-height: 0px; text-indent: -9999px; }
.irc { position: absolute !important; height: 1px; width: 1px; overflow: hidden; clip: rect(1px, 1px, 1px, 1px); }

//editor

.trumbowyg-box, .trumbowyg-editor { min-height: 80px !important; width: 100% !important; border-color: #ccc !important; border-top-color: #999 !important; }
.trumbowyg-box { padding-top: 0 !important; }
.trumbowyg-button-pane { background-color: lighten(@color_4,15%) !important; border-bottom-color: #ccc !important; position: relative !important;

	&:after { content: ""; display: table; clear: both; }

}
table {

	.trumbowyg-box,
	.trumbowyg-editor { margin: 0; }

}

//sorting
.sortable-chosen tr + tr,
.sortable-chosen .note { display: none; }

//start

.container { margin: 0 auto; padding: 0; position: relative; z-index: 2; }

.center_content .main { text-align: center; }

body.admin .main,
body.no_nav .main { border-bottom: 1px solid #ccc; }

.main { padding-bottom: 20px; }
.main > *:last-child { margin-bottom: 0; padding-bottom: 0; }

//ribbon_area

.ribbon_area { background-color: @color_1; padding: 20px 0 0; }

.logo { padding-bottom: 20px; }
.logo a { display: block; width: 100px; height: 100px; background: url("@{image_base}logo.svg") no-repeat 0 0; .transition(opacity); margin: 0 auto;

	.no-svg & { background-image: url("@{image_base}logo.png"); }

}
.logo a:hover { .opacity(.5); }

.btn { text-transform: lowercase; text-align: center;

	> *, > .no_link { display: block; .rounded(5px); padding: 10px 15px; .transition(background-position); color: #fff; border: none; width: 100%; .ossb; }
	> div { padding: 0; }
	&.btn_auto > * { width: auto; .ibm; }
	> *:hover { background-position: 0 45px; color: #fff; }

	&.has_icon > * { padding-top: 8px; padding-bottom: 8px;

		.ico:before { .ico_circle; margin-top: -2px; .font-size(1); }

	}

	&.btn_default > *,
	&.btn_default > .no_link { .gradient-v(#fff,#aaa); background-color: #fff; color: @color_1; }
	&.btn_dark > *,
	&.btn_dark > .no_link { .gradient-v(#333,#000); background-color: #333; }
	&.btn_grey > *,
	&.btn_grey > .no_link { .gradient-v(#666,#444); background-color: #666; }
	&.btn_primary > *,
	&.btn_primary > .no_link { .gradient-v(@color_2,darken(@color_2,10%)); background-color: @color_2; }
	&.btn_secondary > *,
	&.btn_secondary > .no_link { .gradient-v(@color_3,darken(@color_3,10%)); background-color: @color_3; }

	&.btn_abrsm > *,
	&.btn_abrsm > .no_link { .gradient-v(@abrsm,darken(@abrsm,20%)); background-color: @abrsm; }
	&.btn_trinity > *,
	&.btn_trinity > .no_link { .gradient-v(@trinity,darken(@trinity,20%)); background-color: @trinity; }

	&.btn_error > *,
	&.btn_error > .no_link { .gradient-v(@color_error,darken(@color_error,10%)); background-color: @color_error; }

	> .no_link { .opacity(.5); }

	> button { height: 44px; }

}

.btn_menu, .btn_account, .btn_basket, .btn_sub { width: 45px; position: absolute; top: 20px; right: 20px;

	a { padding-left: 0; padding-right: 0; }

}
.btn_account, .btn_sub { right: auto; left: 20px; }
.logged_in .btn_sub { top: (20px + 45px + 10px); }
.btn_basket { top: (20px + 45px + 10px);

	.number { background-color: #fff; .ibt; position: absolute; top: -5px; right: -5px; .font-size(1); .line-height(2); min-width: 20px; text-align: center; padding: 0 3px; .rounded(9px); color: @color_1; }
	.text, .price { display: none; }

}

//members_navigation

@sml: 37px;

.accordion {

	li { position: relative; }

	.no_link { .opacity(.5);

		.btn { position: absolute; top: 4px; right: 3px; height: (@sml - 1px); }
		.btn > * { height: (@sml - 1px); .line-height(1.3); }

	}
	.no_link.o_100 { .opacity(1); }

	li > a > .ico:before { .font-size(1); position: absolute; top: 13px; right: 15px; width: auto; height: auto; }
	li .toggle:before { display: block; background-color: #fff; width: 41px; height: 41px; top: 0; right: 0; line-height: 41px; cursor: pointer; }

	.tier_inner li .toggle:before { top: 1px; }

	.ui-state-active .i_arrow_down:before,
	.ui-state-active.i_arrow_down:before { content: "\e013"; }

	.active > a { text-decoration: underline; }
	.active > a small { .opacity(1); }

	.main & { margin-bottom: 20px; }
	.main &.abrsm_grades:last-child,
	.main &.trinity_grades:last-child { margin-bottom: 0; }

	//admin in accordion
	.form_line { position: relative; padding: 1px (@sml + @sml + 2px) 1px 0;

		.btn { width: @sml; height: @sml; position: absolute; top: 1px; right: 0; }
		.btn > * { padding: 0; height: @sml; width: @sml; line-height: @sml; }
		.input + .btn { right: (@sml + 1px); }
		input { .box-shadow-inset(2px,2px,4px,0,rgba(0,0,0,.1)); height: @sml; }
		.ico:before { line-height: (@sml - 2px); }

	}
	table { margin-bottom: 1px; }
	table td { .line-height(2); }
	table a { padding: 0; }

	.edit_this { width: @sml; position: absolute; top: 3px; left: 3px; z-index: 1;

		a { padding: 10px; }
		.ico:before { .font-size(1.4); }

	}
	.edit_this + a { padding-left: 45px; padding-right: 45px; }

	table br { display: none; }

}
.no_accordion {

	li > a > .ico:before { display: none; }
	.main & { margin-bottom: 20px; }

}

//admin quick view

.quick_view {

	ul { .ibt; }
	li { width: 25%; text-align: center; }
	.content & li { .ibt; margin-left: 0; padding-left: 0; list-style: none; }
	small { .opacity(.5); .font-size(1.1); min-width: 19px; }
	a { text-decoration: underline; }

}
.qv_8 {

	ul { width: 47.5%; margin-right: 5%; margin-bottom: 10px; }
	ul:nth-child(2n) { margin-right: 0; }
	ul:nth-child(even):nth-last-child(3) ~ ul { margin-bottom: 0; }
	small { display: block; margin-top: -10px; }

}
.qv_1 {

	ul { display: block; }
	li { width: 11.11111111111111%; }
	small { display: block; margin-top: -10px; }

}
#view_abrsm .quick_view a { color: @abrsm; }
#view_trinity .quick_view a { color: @trinity; }

//

.main {

	.tier_1 a.ui-accordion-header { padding-left: 35px; padding-right: 35px; }
	.tier_2 ul > li > a,
	.tier_2 ul > li > .no_link { background-color: #eee; }
	.tier_2 > li:first-child > a { border-top: none; }

	.tier_4 { .osi; }
	.osr { .osr; }
	.tier_2 ul ul > li > a { color: #666; background-color: #f6f6f6; }
	.tier_2 ul ul > li + li > a { border-color: #ccc; }

}

.members_navigation { padding: 0 20px 20px;

	.tier_1 { text-align: left; .osr; .font-size(1.2); }
	.btn .ico:before { margin-left: 0; margin-right: 2.5px; }
	li a, li .no_link { padding-left: 45px; }
	.no_icons li a, .no_icons li .no_link { padding-left: 15px; }
	.tier_1 > li > a .ico:first-child:before { .opacity(1); .font-size(1.6); line-height: 16px; right: auto; left: 15px; }

	.tier_2 ul { .opacity(.9); }

}
.no-js .members_navigation ~ .members_navigation { margin-top: -10px; }

.enews_social { padding: 20px; position: absolute; bottom: -140px; left: 0; width: 100%; background-color: @color_1;

	ul:first-child li { margin-top: 0; }

	.btn .ico:before { .font-size(1.6); margin-left: 0; margin-right: 3px; }

	li { .ibt; width: 23.5%; margin-right: 2%; margin-top: 10px;

		a { display: block; border: 1px solid lighten(@color_1,20%); .rounded(5px); color: #fff; .transition(border-color); }
		a:hover { border-color: #aaa; }
		.ico:before { line-height: 43px; }

	}
	li:last-child { margin-right: 0; }

}

//nav

.navigation { background-color: @color_1; padding: 0 20px 20px;

	.tier_1 {  text-transform: lowercase;

		a, .no_link { .line-height(2.5); padding: 9px 15px 10px; }

	}

	.featured_blog {

		.no_link { color: @color_1; .ctsbi; .font-size(1.4); padding-bottom: 0; text-decoration: underline; }
		.fbh + li a { border-top: none; }
		.fbh ~ li a { border-top-color: #ddd; }

	}

}

.btn_login,
.btn_logout { margin-bottom: 8px; padding-bottom: 8px; border-bottom: 1px solid lighten(@color_1,10%);

	&:last-child { border-bottom: none; margin-bottom: 0; }

}

.tier_1 { .ossb; text-align: center;

	> li { margin-top: 1px;

		> a { .transition(background-position); position: relative; }
		> a:hover,
		&:hover > a { background-position: 0 60px; }

		&:first-child { padding-top: 0; margin-top: 0; }

	}

	a, .no_link { display: block; padding: 11px 15px 13px; .line-height(1.8); }
	td a { line-height: inherit; }

	small { .ctsbi; }

	.tier_inner { .font-size(1.2); }

	.secondary { .osr; }

}

//adding success rate and form guide
@h1: 30px;
@h2: 2px;
.main .has_stats {

	.tier_3 a { padding-bottom: (@h2 + 10px); }

}
.success_rate, .recent_form { margin: 5px 0; }
.success_rate { background-color: #ccc;

	span:first-child { display: block; .gradient-h(darken(@color_success,10%),@color_success); height: @h1; }

}

.recent_form {

	li { .ibt; width: 20%; background-color: #ccc; border-left: 1px solid #fff; height: @h1; }
	.n { background-color: @color_error; }
	.ny { background-color: @color_2; }
	.y { background-color: @color_success; }
	.ico:before { color: #fff; .font-size(1.8); line-height: (@h1 + 2); }

}
.accordion {

	.success_rate, .recent_form { position: absolute; bottom: 0; left: 0; height: @h2; margin: 0; }

	.success_rate { background-color: #fff; width: 75%;

		span:first-child { height: @h2; }

	}
	.success_rate + .note { color: #333; }

	.recent_form { left: auto; right: 0; width: 25%;

		li { border-left-color: #eee; height: @h2; }

	}

	//stats page
	.fill { width: 100%; height: @h1; position: static; background-color: #ccc; padding: 10px; text-align: left;

		span,
		span:first-child { height: (@h1)-20; .ibt; }
		span.green { background-color: @color_success; }
		span.amber { background-color: @color_2; }
		span.red { background-color: @color_error; }

		span[style*="width:0%;"],
		span[style*="width: 0%;"] { display: none; }

	}
	.all_form { position: static; width: 100%; height: (@h1)-20; background-color: #eee; text-align: left;  padding: 0 10px;

		li { height: (@h1)-20; width: @h1; border-left: none; margin-right: 1px; margin-bottom: 1px; }
		li:nth-child(50n) { margin-right: 0; }
		.ico:before { .opacity(1); }

	}
	.note { text-align: left; padding: 3px 10px 4px; background-color: #eee; }
	// .all_form + .note { margin-top: -1%; }

}
table {

	.osr { padding: 0;

		.success_rate { margin: 0; height: @h2; }
		.success_rate span { height: @h2; .ibt; }

	}

}

.white_nav;

body.admin {

	.grey_nav;

}

//content

.mobile_hide { display: none; }
td.mobile_hide,
th.mobile_hide { display: none; }

.mobile_show { display: block; }
td.mobile_show,
th.mobile_show { display: table-cell; }

.btn.mobile_show { margin-bottom: 0; }

.col { border-bottom: 1px solid #ddd; margin-bottom: 20px; padding-bottom: 20px; }
.col:last-child, .col > *:last-child { border-bottom: none; margin-bottom: 0; padding-bottom: 0; }
//packs over-ride
.packs.col > *:last-child { padding-bottom: 5px; }
//shop over-ride
.shop .packs.col { border-bottom: none; padding-bottom: 0; margin-bottom: 0; }

.color_abrsm, .color_abrsm a { color: @abrsm; }
.color_trinity, .color_trinity a { color: @trinity; }
.color_primary { color: @color_2; }

.you_are_here { .osi; .font-size(1.2);

	li { .ibt; position: relative; padding-right: 20px;

		&:before { .icon; display: block; content:"\e014"; position: absolute; top: 50%; margin-top: -12px; right: 7.5px; .font-size(.6); .opacity(.25); }

		a { color: lighten(@color_1,40%); }
		a:hover { color: @color_1; }

	}

}

strong { .ossb; font-weight: normal; }

.space_stuff, .trumbowyg-editor {

	> * { margin-bottom: 20px; }
	> *:last-child { margin-bottom: 0; }

	.btn { margin-top: 10px; margin-bottom: 10px; }

}
.trumbowyg-editor > *:last-child { margin-bottom: 20px; }

.space_stuff + .space_stuff { margin-top: 20px; }
.tabs ~ .space_stuff { margin-top: 0; }

.content, .trumbowyg-editor {

	h1, .h1 { .cti; .font-size(3); .line-height(4.1);

		small { display: block; font-size: 43%; line-height: 43%; .osl; text-transform: uppercase; }

	}
	h2, .h2 { .osl; text-transform: uppercase; .font-size(2); .line-height(3.8); }
	h3, .h3 { .cti; .font-size(2.6); .line-height(3.7); }
	h4, .h4 { .osl; text-transform: uppercase; .font-size(1.6); .line-height(3.4); }
	h5, .h5 { .ossb; }
	h6, .h6 { text-decoration: underline; }

	em { .osi; font-style: normal; }

	p { .font-size(1.4); .line-height(2.5); }
	#faq-show p { .font-size(1.2); .line-height(2.3); .osi; }

	p a { text-decoration: underline; color: @color_2; }
	p a:hover { color: darken(@color_2,10%); }

	li { display: list-item; list-style: outside disc; }
	ol li { list-style: outside decimal; margin-left: 19px; }
	ul li { margin-left: 15px; padding-left: 4px; }
	.trumbowyg-button-pane li { margin-left: 0; padding-left: 0; }
	.tabs li, .pages li, .check_radio li, .alert_message li { .ibt; list-style: none; margin-left: 0; padding-left: 0; }
	.alert_message li { display: block; }
	.basic li { list-style: none; margin-bottom: 20px; margin-left: 0; padding-left: 0; }
	.basic li:last-child { margin-bottom: 0; }

	blockquote { border-left: 2px solid @color_4; padding-left: 20px; }
	blockquote p { .cti; .font-size(2.2); .line-height(3.3); }
	blockquote small { .opacity(.5); }

	.reset { margin-bottom: 0; }

	.serif { .cti; .font-size(1.9); }

	img { .img; }

}
.article-media--full-width img { .img; }
.primary_links {

	a { text-decoration: underline; color: @color_2; }
	a:hover { color: darken(@color_2,10%); }

}
.main > .content,
.main > .basic_wrap { padding: 0 20px; margin: 20px 0 0; }

.list_columns > *, .btn50 { .ibt; margin-right: 1%; margin-bottom: 1%; }
.btn50:last-child { margin-right: 0; }
.btn50, .btn50.new_line { margin-bottom: 0; }

.four_column > * { width: 24.25%;}
.four_column > *:nth-child(4n) { margin-right: 0; }

.three_column > * { width: 32.66666666666667%; }
.three_column > *:nth-child(3n) { margin-right: 0; }

.two_column > *, .btn50 { width: 49.5%; }
.two_column > *:nth-child(2n), .btn50 + .btn50 { margin-right: 0; }

.one_column > * { width: 100%; }

.btn50.new_line { margin-right: 0; width: 100%; }

// .list_columns > *:last-child,
// .list_columns > *:nth-child(odd):nth-last-child(1),
// .list_columns > *:nth-child(odd):nth-last-child(1) ~ li,
// .two_column > *:nth-child(odd):nth-last-child(2),
// .two_column > *:nth-child(odd):nth-last-child(2) ~ li,
// .three_column > *:nth-child(odd):nth-last-child(3),
// .three_column > *:nth-child(odd):nth-last-child(3) ~ li,
// .four_column > *:nth-child(odd):nth-last-child(4),
// .four_column > *:nth-child(odd):nth-last-child(4) ~ li { margin-bottom: 0; }

.tab_set .content { padding-top: 20px; }

//tabs

.tabs { position: relative; border-bottom: 1px solid #ccc; .ossb; margin-top: -5px;

	&.flex { display: flex;

		li { flex-grow: 1; }

	}

	li { .ibb; margin-right: 1px; margin-bottom: -1px; .line-height(1); margin-top: 5px; }
	&.t4 li { width: 24.25%; margin-right: 1%; }
	&.t3 li { width: 32.66666666666667%; margin-right: 1%; }
	&.t2 li { width: 49.5%; margin-right: 1%; }
	li:last-child { margin-right: 0; }
	a { width: 100%; border: 1px solid #ccc; padding: 17px 10px 16px; display: block; color: #333; .opacity(.5); .transition(opacity,.3s,linear); .border-radius(5px,5px,0,0); text-decoration: none !important; text-align: center; }
	.ui-state-active a { background-color: #fff; border-bottom: none; padding-bottom: (1px + 16px); }
	.ui-state-active a, li:hover a { .opacity(1); }

	&:after { .ba; width: 100%; border-bottom: 1px solid #ccc; position: absolute; top: 49px; left: 0; z-index: -1; }

	&.as_btns { border-bottom: none;

		a { .rounded(5px); }
		.ui-state-active a { background-color: #fff; border: 1px solid #999; padding-bottom: 16px; }
		.ui-state-active a, li:hover a { .opacity(1); }

		&:after { display: none; }

	}

}
.space_stuff > .ui-tabs-nav ~ * { margin-bottom: 0; }

//pagination

// .pagination { .font-size(0);

// 	ul { .middle; }

// 	li { .ibt; margin-right: 1%; .font-size(1.2); }
// 	li:last-child { margin-right: 0; }
// 	// .first, .prev, .next, .last { width: 10%; }

// 	.p_5 li { width: 19.2%; }
// 	.p_6 li { width: 15.83333333333333%; }
// 	.p_7 li { width: 13.42857142857143%; }
// 	.p_8 li { width: 11.625%; }
// 	.p_9 li { width: 10.22222222222222%; }
// 	.p_10 { max-width: 525px; }
// 	.p_10 li { width: 9.1%; }
// 	.p_11 li { width: 8.181818181818182%; }
// 	.p_12 li { width: 7.416666666666667%; }
// 	.p_13 li { width: 6.769230769230769%; }

// 	a, .nolink { display: inline-block; text-align: center; width: 100%; .rounded(5px); padding: 5px 0; }
// 	a { background-color: #ddd; .transition(background-color);

// 		&:hover { background-color: #ccc; }

// 	}
// 	.nolink { cursor: default; background-color: #eee; color: #bbb; }
// 	.active .nolink { background-color: @color_1; color: #fff; }

// 	.ico:before { .font-size(1.1); margin-top: -2px; }

// 	.first, .last {

// 		.ico:before { width: auto; margin-left: -2.5px; margin-right: -2.5px; }

// 	}

// }

//form

.form_wrap { background-color: #eee; padding: @pad; .rounded(5px); }

.form_line {

	> label { display: block; .ossb; margin: @pad 0; }
	label a { text-decoration: underline; }
	label small { .osi; .font-size(1.2); .opacity(.5); }
	label small a { text-decoration: none; }
	.center_labels label { text-align: center; }
	&:first-child > label { margin-top: 0; }
	.check_radio {

		label { display: block; .font-size(1.2); position: relative; padding: (@pad / 2) @pad; .rounded(5px); background-color: #ddd; cursor: pointer; .transition(background-color); }
		.no-js & label { padding-left: 30px; }
		label:hover { background-color: #ccc; }
		label.checked { color: #fff; background-color: @color_1; }
		input { position: absolute; top: (@pad + 1px); left: @pad; .opacity(0); cursor: pointer; }
		.no-js & input { .opacity(1); }

	}
	.note { margin-top: 5px; }
	&#faq-show { margin-top: 10px;

		&.inline { margin-top: 0; }
		p:empty { display: none; }

	}
	.note:empty,
	&#faq-show:empty { margin-top: 0; }
	&.tick input { vertical-align: middle; margin-left: 5px; }

	.field_btn { position: relative; padding-right: 55px;

		.btn { width: 45px; position: absolute; top: 0; right: 0; margin: 0; }
		.ico:before { padding-top: 3px; }

	}

}

input[type="text"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="tel"],
input[type="file"],
select,
textarea,
.uneditable,
.fake { .osr; color: @color_1; .ibt; border: 1px solid #ccc; border-top-color: #999; width: 100%; padding: @pad+1 @pad; background-color: #fff; }
.webkit .fake { text-align: left; }
optgroup { .ossb; font-style: normal; font-weight: normal; text-indent: 3px;  }
select { padding: (@pad) (@pad - 4px) (@pad + 1px); .ossb; }
optgroup option { .osr; }
select[disabled] { .opacity(.25); }
.fake { padding: (@pad - 1.5px) @pad (@pad - 0.5px); }
// table .fake { border: none; }
textarea { height: 150px; resize: vertical; }
input[type="file"] { border: none; padding: 6px 6px 7px 0; }

.basic_form {

	.h2 { .ossb; text-transform: uppercase; }

}

.error {

	input[type="text"], input[type="password"], input[type="email"], input[type="url"], input[type="tel"], select, textarea, .uneditable { border-color: @color_error; }
	label { color: @color_error; }

}
label.error { color: @color_error;

	& + * > * { border-color: @color_error; }

}

.alert_message { position: relative; text-transform: none; padding: @pad (@pad + 25px); height: auto; text-align: center;

	strong { text-transform: uppercase; }
	.ico:before { width: auto; margin-right: 5px; }
	ul { margin-top: (@pad / 2); }
	li { color: #fff; }

}
.alert_message p { margin: 0; color: #fff; }
.alert_message p a,
.alert_message li a { color: #fff; text-decoration: underline; }
.msg_close { color: #000; .font-size(2.5); line-height: ((@pad*2) + 15px); font-weight: bold; .opacity(.2); text-shadow: 0 1px 0 #FFFFFF; position: absolute; right: @pad; top: 3px; .transition(opacity, .3s, linear); text-decoration: none; }
.content .msg_close { top: 5px; }
.msg_close:hover { .opacity(.5); color: #000; }
.alert_message.error { background-color: @color_error; }
.alert_message.warning { background-color: @color_warning;

	p, li, p a, li a { color: #333; }

}
.alert_message.notice { background-color: @color_notice; }
.alert_message.success { background-color: @color_success; }

form {

	.responsive_table_wrap { margin: 20px 0; }
	> select{ margin-bottom: 1px; }

}

//tags
ul.tagit { padding: 1px; border: 1px solid #ccc; border-top-color: #999; overflow: auto;

    li { display: block; float: left; margin: 1px; padding: 6px 30px 7px 7px; }
    li.tagit-new { padding: 0; }
    li.tagit-choice { position: relative; line-height: inherit; border: 1px solid #ccc;

        a.tagit-label { cursor: pointer; text-decoration: none; }

        .tagit-close { cursor: pointer; position: absolute; right: 6px; top: 50%; margin-top: -8px; line-height: 17px; }
        .tagit-close:after { position: relative; width: 15px; height: 15px;line-height: 15px; text-align: center; color: #888; content: "✖"; display: block; }

        input { display: block; float: left; margin: 2px 5px 2px 0; }

        .tagit-close .text-icon { display: none; }

    }

    input.tagit-hidden-field { display: none; }

    input[type="text"] { border: none; margin: 0; padding: 10px; width: inherit; background-color: inherit; outline: none; }

}
ul.tagit-autocomplete { z-index: 10;

	li { .ibt; background-color: #fff; border: 1px solid #ccc; padding: 10px; cursor: pointer; }
	li + li { margin-left: -1px; }

}

//table

@table_border: @color_4;

table { border: 1px solid @table_border !important; border-collapse: separate; margin: 0 auto; width: 100%;

	table,
	table th,
	table td { border-color: #888 !important; }
	table .link_prev { margin-top: -1px; }
	table .link_prev td { border-top: none; padding-top: 0; }

	th, td { padding: @pad; }
	th { background-color: lighten(@color_4,15%); font-weight: normal; text-align: left; .ossb; }
	td { vertical-align: top; text-align: left; background-color: #fff; }
	th.sml { .font-size(1); .line-height(1.2); padding-top: 0; padding-bottom: 0; }
	td.sml { .font-size(1.2); .line-height(2); }

	th + th, td ~ td { border-left: 1px solid @table_border; }
	tr ~ tr > td { border-top: 1px solid @table_border; }

	tr.hide + tr > td { border-top: none; }

	.hide_it { display: none; }
	td.hide_it:first-child + td,
	th.hide_it:first-child + th { border-left-color: transparent; }

	.icon_td { padding: 0; width: 45px; text-align: center; }
	.vatop { vertical-align: top; }
	.vamiddle,
	tr.vamiddle > * { vertical-align: middle; }
	.vabottom { vertical-align: bottom; }
	.btn_td { padding-top: 0; padding-bottom: 0; }
	.icon_td a { width: 45px; display: block; margin: 0 auto; }
	.icon_td.vatop a { margin-top: @pad; }
	.icon_td.vabottom a { margin-bottom: @pad; }
	.sort a { cursor: move; }
	.icon_td input { position: relative; top: -3px; }
	.icon_td .link_it { top: -6px; }
	.icon_td input[type="radio"] { top: 0; margin-top: 16px; }
	.for_input + .icon_td input[type="radio"] { margin-top: 19px; }
	.icon_td .ico:before { line-height: 45px; }

	.nowrap { white-space: nowrap; }

	.sml_td { width: 55px; }
	.med_td { width: 110px; }

	.for_input { padding: 3px; vertical-align: middle; }

	.notlive td > * { .opacity(.25); .transition(opacity); }
	.notlive:hover td > * { .opacity(1); }
	.notlive .i_show:before { content: "\e2da"; }

	input + *,
	td .fake + * { margin-top: 10px; }
	input[type="hidden"] + * { margin-top: 0; }

	input + .note,
	audio + .note { margin-top: 5px; margin-bottom: 10px; }

	input + .note:last-child { margin-bottom: 0; }

	.error { color: @color_error; }
	.success { color: @color_success; }

	.tab_set & td.content { padding-top: @pad; }

	.icon_td button { border: none; background-color: transparent; padding: 0; width: 100%; }

	small { .font-size(1.1); }

	//date picker

	&.ui-datepicker-calendar { width: 280px; .osr;

		th, td { text-align: center; width: 40px; .font-size(1.2); .line-height(1.8); }
		td { padding: 0; }
		td a { display: block; padding: 10px; }
		.ui-state-active { color: @color_2; }

	}

}

.scope_col {

	th { border-bottom: 1px solid @table_border; }

}
.scope_row {

	th { border-right: 1px solid @table_border; text-align: right; }
	tr ~ tr > th { border-top: 1px solid @table_border; }

}
.responsive_table_wrap { width: 100%; overflow-x: auto; overflow-y: hidden; .box-shadow-inset(0,0,10px,0,#000);

	// table { min-width: 500px; }

	> * { .opacity(.9); }

	tr.disabled { .opacity(1); }
	tr.disabled > * > * { .opacity(.25); }

}
.t_fixed { table-layout: fixed; }

//date picker

.ui-datepicker { margin: 5px 0; background: #fff; border-top: 1px solid @table_border; .box-shadow(0,0,50px,0,rgba(0,0,0,.2)); display: none; }
.ui-datepicker-buttonpane { border-bottom: 1px solid @table_border;

	button + button { margin-left: 0; }

}
.ui-datepicker-current,
.ui-datepicker-close { border: none; text-align: left; }
.ui-datepicker-next,
.ui-datepicker-prev,
.ui-datepicker-current,
.ui-datepicker-close { width: 50%; .ibt; .ossb; .font-size(1.4); .line-height(2); padding: 10px; background-color: rgba(0,0,0,.05); cursor: pointer; }
.ui-datepicker-prev,
.ui-datepicker-current { border-left: 1px solid @table_border; }
.ui-datepicker-next,
.ui-datepicker-close { text-align: right; border-left: 1px solid lighten(@table_border,10%); border-right: 1px solid @table_border; }
.ui-datepicker-title { display: block; text-align: center; background-color: rgba(255,255,255,.5);  padding: 10px; border: 1px solid @table_border; margin-bottom: -1px; color: @color_2; }

//intro

.main > .intro { color: #fff; background: url("@{image_base}playing_piano.jpg") no-repeat 80% 100%; text-align: center; padding: 20px; margin: 0;

	.btn { .ibt; width: 49.5%; margin-right: 1%; margin-top: 15px; margin-bottom: 0; max-width: 220px; }
	.btn + .btn { margin-right: 0; }

	h1,
	p { max-width: 440px; .middle; }
	p a { color: #fff; }

	h1 span,
	p span { display: none; }

	h1 a { color: #fff; text-decoration: underline; }

}

//maestro

@keyframes play {
	100% { background-position: -20160px; }
}

.maestro { position: relative; width: 240px; height: 240px; background: url("@{image_base}maestro_sprite.png") no-repeat 0 50%; margin-left: auto; margin-right: auto; z-index: 1; animation: play 2.2s steps(84) infinite;

	> * { .transform(rotate(7deg)); text-align: center; width: 130px; height: 130px; background-color: @color_2; .rounded(100%); position: absolute; top: 10px; right: 0; padding: 32px 20px; color: #fff; }
	> a:hover { color: #fff; }

	.h2 { .cti; .font-size(1.8); .line-height(2.1); text-decoration: underline; }
	p { .font-size(1.2); .line-height(1.4); .opacity(.7); }

	// .no-svg & { background-image: url("@{image_base}maestro.png"); }

}
.dashboard_page .maestro { margin-top: 30px; margin-bottom: 30px; }

.home_page {

	.main { padding-bottom: 300px; }
	.maestro { position: absolute; bottom: 30px; left: 50%; margin-left: -120px; }

}

.maestro_the_dog { .img; max-width: 200px; margin: 20px auto; }

//home page plans etc

.plans_list, .packs {

	h1 { position: relative;

		&:after { .ba; width: 20px; border-bottom: 1px solid @color_1; position: absolute; bottom: 0; }

	}
	.btn { text-transform: uppercase; .ibt; position: absolute; }

	.item .has_pics {

		h1 { margin-top: 15px; }
		.thumb img { display: block; width: 137px; height: auto; }

		.thumb ~ a { display: none; }

		.price span { .box-shadow(0,0,10px,0,rgba(0,0,0,.2)); }

	}

}
.plans_list { padding: 20px;

	> div { .gradient-v(#f5f5f5,@color_4); padding: 5px;

		> div { padding: 20px 15px 15px; text-align: center; position: relative; border: 1px solid #fff; }

	}
	.btn { width: 49.5%; bottom: 15px; left: 0px; padding-left: 15px; }
	.btn + .btn { left: auto; right: 0px; padding-right: 15px; padding-left: 0; }
	table ~ .btn { margin-bottom: 20px; }
	ul + .btn_primary,
	h3 ~ .btn,
	.set .btn { width: 100%; padding-left: 0; padding-right: 0; margin-left: 2.5px; margin-right: 2.5px; position: static; max-width: 200px; }

	h1 { padding-bottom: 20px; .osl; .font-size(2); .line-height(3.8); text-transform: uppercase; margin-bottom: 20px;

		&:after { left: 50%; margin-left: -10px; }

	}
	h1 strong { font-weight: normal; .cti; display: block; .font-size(3.6); text-transform: none; }

	ul li, p, ul.reset { .font-size(1.2); .line-height(2); margin-bottom: 20px;

		&:last-child { margin-bottom: 0; }

	}
	ul.reset li { margin-bottom: 0; }
	h3 { margin-bottom: 20px; .ossb; }

	p a, ul a { text-decoration: underline;

		&.no_u { text-decoration: none; }

	}

	table { margin-bottom: 20px; }
	table a { color: @color_2; }

	//tabs in plans_list

	.tabs { border-bottom-color: @color_4; margin-bottom: 20px;

		li, li:last-child { margin-bottom: -1px; .font-size(1.4); }
		a { border-color: @color_4; .gradient-v(#fff,#EDEBEA); }

	}

	//message in plans list
	.alert_message { margin-bottom: 20px; }

	//holding

	.soon {

		.btn { width: 100%; max-width: 100%; margin-top: 20px; padding-right: 15px; }
		ul + .btn_primary { position: absolute; padding-left:  15px; }
		.btn_dark { .font-size(1.1); }

	}

	//rows

	&.rows {

		> div { margin-bottom: 10px; }
		> div:last-child { margin-bottom: 0; }

		img { .img; max-width: 200px; margin-bottom: 20px; .rounded(100%); margin-left: auto; margin-right: auto; }

		h1 strong { display: inline; .font-size(2.3); }

		p span { .ibt; }

		.btn { margin-bottom: 5px; position: static; width: auto; padding: 0; }

	}

}
.plans_list.plans_3 > div > div,
.packages_page .plans_list > div > div { padding-bottom: 80px; }

.extras_list, .promotions { padding: 0 20px; margin: 20px 0; border-bottom: 1px solid #ccc;

	.item { background-color: @color_1; color: #fff; padding: 20px; position: relative; margin-bottom: 1px;

		a, .nolink  { color: #fff; }

		&:last-child { margin-bottom: 0; }

		p { .osi; .font-size(1.1); .line-height(2); z-index: 3; position: relative; width: 80%; }

	}

	.h1 { .font-size(1.6); .line-height(2.9); text-transform: uppercase; margin-bottom: 10px; z-index: 3; position: relative;

		.ico:before { .ico_circle; }

	}

	.extra_more { display: block; .gradient-v(darken(@color_2,20%),@color_2); background-color: @color_2; position: absolute; bottom: 0; right: 0; padding-top: 15px; width: 110px; padding-right: 20px; z-index: 1; .transition(background-position);

		.ico:before { line-height: 45px; text-align: right; .font-size(1); color: #fff; .opacity(.5); }

		&:hover { background-position: 0 -60px; }

	}
	span.extra_more { height: 60px; .opacity(.5); }
	span.extra_more:hover { background-position: 0 0; }
	.no-pointerevents & .extra_more { width: 45px; padding-top: 0; padding-right: 0;

		.ico:before { text-align: center; }

	}

	.lighter { background-color: @color_4; color: @color_1;

		a { color: @color_1; }

		.extra_more { .gradient-v(darken(@color_1,100%),@color_1); background-color: @color_1; }

		.h1 .ico:before { background-color: rgba(255,255,255,.9); }

	}

	.item + .guide { margin-top: (30px + 20px); }

}
.extras_list { padding-bottom: 20px;

	&:last-child { border-bottom: none; padding-bottom: 0; }
	.item:after { .ba; background: url("@{image_base}curl_dark.svg") no-repeat 0 0; width: 146px; height: 76px; position: absolute; bottom: 0; right: 0; pointer-events: none; z-index: 2; }

	.no-svg & .item:after { background-image: url("@{image_base}curl_dark.png"); }
	.no-pointerevents & .item:after { display: none; }

	.lighter:after { background: url("@{image_base}curl_light.svg") no-repeat 0 0; }
	.no-svg & .lighter:after { background-image: url("@{image_base}curl_light.png"); }

	//music teacher awards
	.mta { background-color: #192162; display: flex !important; align-items: center; justify-content: center;
		img { width: 100%; height: auto; }
		&:after { display: none; }

	}
	//xmas
	.xmas { padding: 0; background-color: transparent;

		&:after { display: none; }

		h3, p { color: #333; text-align: center; padding: 0 15px; }
		h3 { margin-top: 30px; .line-height(1.8); }
		p { width: 100%; margin-top: 3px; }

	}

}
.plans_list + .extras_list { margin-top: 0; }
.subscription_plans .extras_list { padding-bottom: 20px; }
.subscription_plans .extras_list:last-child { padding-bottom: 0; }

//packages list

.packages_page {

	.plans_list { width: 100%; padding: 0; margin-top: 20px;

		p { .font-size(1.4); }
		// p strong { padding-left: 10px; }

		.packages_1;

	}
	select { text-align: center; }
	.form_line + .form_line { margin-top: 5px; }

	.alert_message { margin-top: 20px; }
	.alert_message + .alert_message { margin-top: 5px; }

}

.promotions {

	.basic_wrap & { padding-left: 0; padding-right: 0; }

	&.slider { padding-bottom: (22.5px + 20px); }
	&:last-child { border-bottom: none; margin-bottom: 0; }
	.item { padding: 0; margin: 0; .gradient-h(@color_3,@color_2); margin-bottom: 1px; text-align: center; .rounded(5px); }
	.item:last-child { margin-bottom: 0; }
	.item > a,
	.item > .nolink { display: block; padding: 20px; .transition(opacity); }
	.item > a:hover { .opacity(.5); }

	.h1, .item p { padding: 0; width: auto; }
	.h1 {

		.ico:before { margin-left: 0;}

	}

	.btn { .ibt; width: 45px; position: absolute; bottom: -22.5px; left: 50%; margin-left: -46px;

		a { border: 1px solid #ccc; }
		.ico:before { .font-size(1.1); }

	}
	.btn + .btn { left: auto; right: 50%; margin-left: 0; margin-right: -46px; }

	//slick
	.slick-slider { .gradient-h(@color_3,@color_2); }
	.slick-slide { background: none; margin-bottom: 0; }
	.slick-slide {

		a { padding-bottom: 42.5px; }
		p { min-height: 40px; }

	}

	.col & { border-bottom: none; }

	//no-js
	.no-js & { padding-bottom: 20px; }

}

//home page testimonials

.testimonial_examples { text-align: center; padding: 0 20px; position: relative;

	blockquote { .osi; padding-top: 50px; }

	.no-js & { padding-bottom: 20px;

		blockquote { padding-top: 20px; }
		.item:first-child blockquote { padding-top: 50px; }

	}

	blockquote + div { .font-size(1.2); .ossb;

		span { .opacity(.5); }

	}

	p { margin-bottom: 20px; }

	.btn { .ibt; width: 45px; margin: 20px 1px 0;

		a { border: 1px solid #ccc; }
		.ico:before { .font-size(1.1); }

	}

	.slick-slide { background-color: #fff; .transition(opacity); }

}

.testimonials_more { position: absolute; top: 0px; left: 50%; margin-left: -15px;

	a { .transition(opacity); }
	a:hover { .opacity(.5); }
	.ico:before { .circle; background-color: @color_2; color: #fff; }

}

//testimonials page
.testimonial_details { text-align: left; padding-top: 20px;

	.item { padding: 20px;  border: 1px solid #eee; border-bottom-color: #ddd; border-right-color: #ddd; }
	blockquote { padding-top: 0; .osl; .font-size(1.4); .line-height(2.5); }
	p { margin-bottom: 5px; }

}

//aural test training

//flexbox for order

.att_item, .epp_item {

	.basic_wrap + .basic_wrap { display: flex; flex-direction: column; }

	.choices { order: 2; margin-top: calc(~"20px + 0px"); padding-bottom: calc(~"1px - 1px"); margin-bottom: calc(~"1px - 1px"); border-bottom: calc(~"1px - 1px"); }
	.test_items { order: 1; }

}

.choices {

	> h1 { .ossb; text-transform: uppercase; }

}

.abrsm_grades { .abrsm_nav; }
.trinity_grades { .trinity_nav; }
.combined_grades { .combined_nav; }

.col_2 + .test_items { position: relative;

	> *,
	.set > *,
	.question_wrapper > *,
	.tab_set > * { margin-bottom: 20px; }
	> *:last-child,
	.set > *:last-child,
	.question_wrapper > *:last-child,
	.tab_set > *:last-child,
	// .tabs,
	table .tab_set > * { margin-bottom: 0; }

	// .my_exam_practice_packs_page & > h1 { margin-bottom: 7px; }

	hr { margin-top: 0; }

	h1 { text-transform: uppercase; }
	h1:first-child { padding-left: (40px + 10px); }

	h1 span { .ossb; }
	h1 small { .cti; .font-size(1.3); text-transform: none; }
	h1 strong { display: block; text-align: center; width: 40px; height: 40px; .line-height(4); color: #fff; .rounded(20px); margin-right: 15px; background-color: @color_1; position: absolute; top: -8px; left: 0; }

	// .my_exam_practice_packs_page & h1 strong { .font-size(1); }

	&.abrsm_items h1 strong,
	&.abrsm_items:before { background-color: @abrsm; }
	&.abrsm_items h1 span,
	&.abrsm_items label strong { color: @abrsm; }

	&.trinity_items h1 strong,
	&.trinity_items:before { background-color: @trinity; }
	&.trinity_items h1 span,
	&.trinity_items label strong { color: @trinity; }

	&.combined_items h1 strong,
	&.combined_items:before { background-color: lighten(@color_1,30%); }
	&.combined_items h1 span,
	&.combined_items label strong { color: lighten(@color_1,30%); }

	h2 { .ossb; text-transform: uppercase; }

	.description { .font-size(1.2); .line-height(2.3); }
	.extra { .opacity(.5); }

	> .note, .set .note, .question_wrapper .note { margin-top: -15px; }

	.cta,
	.answer,
	.help {

		.ico:before { .ico_circle; margin-top: -2px; .font-size(1); }

	}
	.cta a { padding-top: 8px; padding-bottom: 8px; }

	.answer, .help { color: #fff; .rounded(5px); padding: 7px 10px 8px;

		.h1 { .font-size(1.6); .line-height(2.7); text-transform: uppercase; .ibm; margin: 0 5px; }
		p { .osi; .font-size(1.2); .line-height(2.3); .ibm; }
		.ico:before { .font-size(1.8); line-height: (@h1 + 1px); }

	}

	.correct { background-color: @color_success;

		.ico:before { content: "\e320"; }

	}
	.incorrect { background-color: @color_error;

		.ico:before { content: "\e324"; }

	}
	.neutral { background-color: @color_2;

		.ico:before { content: "\e334"; }

	}
	.answer + .answer { margin-top: 10px; }

	.help { background-color: @color_4; color: @color_1;

		.ico:before { background-color: #fff; .font-size(1.6); }

	}

	.help_player .help { margin-bottom: 10px; }

	> * > li { display: list-item; margin-left: 13px; .osi; .font-size(1.2); .line-height(2.3); padding-left: 5px; }
	> ol > li { list-style-type: lower-alpha; }
	> .toc > li { list-style-type: decimal; }
	> ul > li { list-style-type: disc; }

	.sr_rf + .guide { margin-top: 40px; }
	.sr_rf + .help_player { margin-top: -5px; }

	.answer + .sr_rf { margin-top: 20px;

		.note { margin-top: 0; }

	}

	p a, ul a { text-decoration: underline; }

	img { .img; max-width: 470px; margin-left: auto; margin-right: auto; }

	.btn { .transition(opacity); }

	//toc

	.toc li { .opacity(.5); }
	.toc .active { .opacity(1); }

}
.note { .osi; color: lighten(@color_1,50%); .font-size(1.2); .line-height(2.3); }

//scales pages
#item_style_content { margin-top: 30px; }

//player
.player { .gradient-v(lighten(@color_1,10%),@color_1); padding: 9px 10px;

	> * { .ibm; margin-right: 1%; margin-top: -1px; }
	> *:last-child { margin-right: 0; }
	button { border: none; padding: 0; .gradient-v(#666,#444); background-color: #666; height: 24px; width: 12%; .transition(background-position); .rounded(3px); }
	button:hover { background-position: 0 24px; }
	.ico:before { color: #fff; line-height: 24px; }

	.vol_btn { margin-right: 0; }

	.time { color: #fff; .opacity(.5); .font-size(1); width: 15%; text-align: center; }

	.track { height: 12px; .gradient-v(#333,#666); .box-shadow-inset(2px,2px,2px,0,#111); .rounded(3px); position: relative; }
	.time_track { width: 42%; }
	.volume_track { width: 15%; }

	.tracker { width: 24px; height: 24px; .rounded(12px); .gradient-v(#666,#444); border: 2px solid #999; position: absolute; top: 0; left: 0; margin-top: -6px; cursor: pointer; .transition(border-color); }
	.tracker:hover { border-color: #fff; }

	.volume_track { display: none; }

}
//guide
.guide { position: relative; z-index: 2; margin-bottom: 20px; width: 100%;

	.guide_reset();

	a { display: block; .gradient-h(@color_2,@color_3); color: #fff; .rounded(5px); }

	strong, span { display: inline-block; .transition(opacity); }
	a:hover {

		strong, span { .opacity(.5); }

	}

	strong { text-transform: uppercase; }
	span { .osi; .font-size(1.1); .line-height(2.3); }

	&:before { display: block; content:""; width: 200px; height: 150px; background-repeat: no-repeat; background-position: 0 0; .background-size(200px,150px); position: absolute; pointer-events: none; }

	&:after { .ctsbi; display: block; .font-size(1.7); .transform(rotate(-18.5deg)); .origin(center center); width: 200px; text-align: center; position: absolute; top: 56px; left: 50%; margin-left: -100px; pointer-events: none; }

	&.abrsm_trinity {

		&:before { background-image: url("@{image_base}guides.png"); }
		&:after { display: none; }

	}
	&.abrsm_guide { color: @abrsm;

		&:before { background-image: url("@{image_base}abrsm_guide.png"); }

	}
	&.trinity_guide { color: @trinity;

		&:before { background-image: url("@{image_base}trinity_guide.png"); }

	}

	&.abrsm_g1-5:after,
	&.trinity_g1-5:after { content:"1-5"; .font-size(1.2); }
	&.trinity_g0:after { content:"i"; }
	&.abrsm_g1:after,
	&.trinity_g1:after { content:"1"; }
	&.abrsm_g2:after,
	&.trinity_g2:after { content:"2"; }
	&.abrsm_g3:after,
	&.trinity_g3:after { content:"3"; }
	&.abrsm_g4:after,
	&.trinity_g4:after { content:"4"; }
	&.abrsm_g5:after,
	&.trinity_g5:after { content:"5"; }
	&.abrsm_g6:after,
	&.trinity_g6:after { content:"6"; }
	&.abrsm_g7:after,
	&.trinity_g7:after { content:"7"; }
	&.abrsm_g8:after,
	&.trinity_g8:after { content:"8"; }
	&.abrsm_g6-8:after,
	&.trinity_g6-8:after { content:"6-8"; .font-size(1.2); }

}

//search

.show_order {

	li:first-child { width: 19%; padding: 5px 0; }
	li { .ibt; .font-size(1.1); margin-right: 1%; width: 19.25%; }
	li:last-child { margin-right: 0; }
	strong, a { .ibt; padding: 5px 10px; .rounded(5px); text-align: center; width: 100%; }
	a { background-color: #ddd; .transition(background-color); }
	a:hover { background-color: #ccc; }
	strong { background-color: @color_1; color: #fff; }

}

.show_order + .show_order {

	li:first-child { width: 19%; }
	li { width: 26%; }

}

.searching_for { padding: 12px 0; .osi; .opacity(.5); }
.searching_for:first-child { padding: 0; }

//

//new search bar

.search_bar { position: relative; .font-size(1.2); padding-bottom: 20px; border-bottom: 1px solid #ddd; margin-bottom: 20px; z-index: 1;

	.filter h2, .sort_show .current { padding: 9px 10px; cursor: pointer; position: relative; border: 1px solid #ccc; .rounded(5px);

		.ico:before { position: absolute; top: 12px; right: 13px; }

		&.hover { .border-radius(5px,5px,0,0); border-bottom-color: #ccc;

			.ico:before { content: "\e013"; }

		}
		&::-moz-selection{ background: #fff; color: @color_1; }
		&::selection { background: #fff; color: @color_1; }

		.no-js & { .rounded(0); }

	}

	.ico:before { color: @color_1; .ibm; width: auto; .font-size(1); }

	//filter

	.filter { position: relative; z-index: 3; margin-top: 10px;

		h3 { margin: 5px 0; }

		.list:first-child h3 { margin-top: 0; }

		.clear { .ibt; text-align: center; padding: 4px 7px 3px; border: 1px solid #ccc; .rounded(5px); .gradient-v(#fff,#eee); position: absolute; bottom: 5px; right: 37px; }
		small { .opacity(.5); .font-size(.9); padding-left: 5px; }

		.btn { margin-top: 10px; }

	}

	.lists { padding: 10px; position: absolute; left: 0; top: 100%; border: 1px solid #ccc; .border-radius(0,0,5px,5px); border-top: none; background-color: #fff; .box-shadow(0,4px,4px,-4px,rgba(0,0,0,.2)); .opacity(0); width: 100%; }
	.lists > * { max-height: 400px; overflow: auto; }

	.list {

		li { .ibt; margin: 0 1px 1px 0; }
		li > span,
		.active > span { display: block; padding: 5px 8px; .rounded(5px); }
		li > span { background-color: #eee; .transition(~'none'); cursor: pointer; }
		li > span:hover { color: #333; }
		.active > span { background-color: @color_2; color: #fff; position: relative; padding-right: 35px; }
		.active > span:hover { color: #fff; }
		li > span a { display: none; }
		.active > span a { display: block; color: #fff; position: absolute; top: 0; right: 0; width: 35px; line-height: 35px; text-align: center; .font-size(2); }
		.more > a { .gradient-v(#fff,#eee); border: 1px solid #ccc; padding: 4px 7px; }
		.more > a .ico:before { line-height: 10px; margin-left: 3px; }

	}

	//sort show

	.sort_show { margin-top: 10px; position: relative; z-index: 2;

		li { display: block; margin-right: 0; }

	}
	.sort_show > div { .ibt; padding-left: 35px; position: relative;

		> div { position: relative; }

	}
	.sort_show label { .line-height(4.5); position: absolute; left: 0; top: 0; width: 35px; }
	.sort_show select { .ibm; padding-top: 12px; padding-bottom: 12px; }

	.sort { width: 62.5%;

		.direction { width: 22%; }
		.current { .ibt; }
		select, .current, .lists { width: 78%; }
		.ico:before { .font-size(1.2); }

		&.no_direction {

			select, .current, .lists { width: 100%; }

		}

	}
	.sort_show > .show { width: 37.5%; padding-left: 50px;

		label { width: 50px; padding-left: 10px; }

	}

	.direction { .ibm; padding-left: 1px;

		> a { display: block; .rounded(5px); .gradient-v(#fff,#eee); color: @color_1; border: 1px solid #ccc; text-align: center; }
		.ico:before { line-height: 43px; }

	}

}

//pages

.pages { min-height: 45px; .font-size(0);

	.pagination { display: flex; }
	li { padding-right: 1px; .font-size(1.2); flex: 1; } // 10 boxes
	li:first-child,
	li:last-child { .font-size(2);

		> * { padding: 7px 0 11px; } 

	}

	a { .gradient-v(#fff,#eee); }
	a, span { display: inline-block; text-align: center; width: 100%; .rounded(5px); border: 1px solid #ccc; padding: 9px 0; }

}
.content .pages { width: calc(~"100% + 1px"); }
.content .pages ul { width: 100%; }

.search_bar--1-page .pages { display: none; }
.search_bar--2-page .pages li { width: 25%; } // 4 boxes
.search_bar--3-page .pages li { width: 20%; } // 5 boxes
.search_bar--4-page .pages li { width: 16.66666666666667%; } // 6 boxes
.search_bar--5-page .pages li { width: 14.28571428571429%; } // 7 boxes

.no-js .filter h2:hover ~ .lists,
.no-js .sort_show .current:hover ~ .lists,
.no-js .lists:hover { display: block; .opacity(1); }
.no-js .lists,
.no-js .list .more { display: none; }

.js .filter .list .more ~ li { display: none; }
.js .filter .list .more.hover ~ li { .ibt; }

.search_bar_form { position: relative; z-index: 2; }
.search_bar_form + * { .transition(filter,linear,.15s); }
.search_bar_form.blur + * { filter: blur(5px); }

//

//packs

.packs {

	.item { .gradient-v(#f5f5f5,@color_4); padding: 5px;

		> div { border: 1px solid #fff; padding: 15px; position: relative; }

	}

	.item { margin-bottom: 20px; position: relative; overflow: hidden; }
	.item > div { padding-bottom: (15px + 45px + 10px); }
	.item > div > .info > * { margin-bottom: 10px; }
	.item > div > h1 { margin-bottom: 20px; }
	.item > div > .info > *:last-child { margin-bottom: 0px; }

	h1 { .ossb; .line-height(1.8); }
	.new h1, .package h1, .special h1, .current h1 { padding-right: 50px; }
	h1:after { width: 10px; bottom: -9px; }
	h1 small { .cti; text-transform: none; .font-size(1.3); .line-height(1.8); display: inline-block; }

	.btn { bottom: 15px; right: 0; padding-right: 15px; width: 49.5%; margin-bottom: 0;

		a, button { .rounded(5px); .font-size(1.2); padding-left: 0; padding-right: 0; .line-height(1.4); }
		button { height: 45px; }

		.ico:before { .font-size(1); margin-left: -1px; }
		button .ico:before { margin-top: 3px; }
		a .ico:before { margin-top: -2px; }

		small { .font-size(.8); display: block; .opacity(.5); }

	}
	.one_btn .btn { width: 100%; padding-left: 15px; }
	.price { width: 49.5%; text-align: center; .ossb; position: absolute; left: 0; padding-left: 15px; bottom: 15px; margin-bottom: 0; .font-size(1.2); z-index: 3;

		span { display: block; .line-height(1.4); background-color: #fff; .rounded(5px); padding-top: 9px; padding-bottom: 9px; }
		span small { .font-size(.8); display: block; }

	}
	.qty { position: absolute; z-index: 3; }
	.qty input { width: 100%; height: 45px; padding: 8px; }
	.price + .btn a { padding-top: 15px; padding-bottom: 16px; }
	.details, .additional, .pieces, .spec, ul li, p { .font-size(1.2); .line-height(2.1); }
	// p, .player, .video { margin-top: 30px; }
	// p + p { margin-top: 0; }
	ul li { display: list-item; list-style: outside disc; margin-left: 15px; padding-left: 4px; }
	.spec > a:first-child { .board_label; background-color: @color_1; }
	.additional { .osi; .opacity(.5); }
	&.details .additional { margin-bottom: 10px; }

	.new:before,
	.package:after,
	.current:after,
	.special:before,
	.selected:before { display: block; .ossb; color: #fff; text-align: center; .transform(rotate(45deg)); position: absolute; z-index: 1; width: 150px; top: 17px; right: -47px; .font-size(1.2); .line-height(2); }
	.new:before { content:"NEW"; }
	.package:after { content:"PACKAGE"; }
	.current:after { content:"CURRENT"; }
	.special:before { content:"SPECIAL"; }
	.selected:before { content:"SELECTED"; }
	.new.package:before { top: 7px; right: -57px; }
	.special.package:before { top: 12px; right: -52px; }
	.new.package:after { top: 21px; right: -44px; }
	.special.package:after { top: 26px; right: -39px; }

	.abrsm_pack {

		.spec > a:first-child { background-color: @abrsm; }
		&.new:before { .gradient-h(lighten(@abrsm,30%),@abrsm); }
		&.package:after,
		&.current:after { .gradient-h(@abrsm,@color_1); }

	}
	.trinity_pack {

		.spec > a:first-child { background-color: @trinity; }
		&.new:before { .gradient-h(lighten(@trinity,30%),@trinity); }
		&.package:after,
		&.current:after { .gradient-h(@trinity,@color_1); }

	}
	.special:before { .gradient-h(lighten(@color_3,30%),@color_3); }
	.special.package:after,
	.selected:before,
	.product.package:after { .gradient-h(@color_1,lighten(@color_1,30%)); }

	.has_more { .packs_3_0; }

	.note { color: @color_1; .opacity(.5); }

}
.basic_wrap > .packs { margin: 20px 0; }
.basic_wrap > .packs:last-child { margin-bottom: 0; }

//books

.item.book > div { padding-top: 130px; background-repeat: no-repeat; background-position: 7px 10px; .background-size(150px,114px); }
.item.book_1 > div { background-image: url("@{image_base}book_1.png"); }
.item.story_book > div { background-image: url("@{image_base}story_book.png"); }

//packs full width list

.col_3 {

	.item { margin-bottom: 1%; }

}

//salvattore for blog (see layout.less & 500, 800, 1000 & 1200 for triggers)
.column { .ibt;

    > * { margin-bottom: 20px; }

}
.size-1of1 { width: 100%; }
.size-1of2 { width: 48.75%; margin-right: 2.5%; }
.size-1of3 { width: 31.66666666666667%; margin-right: 2.5%; }
.size-1of2:nth-child(2n),
.size-1of3:nth-child(3n) { margin-right: 0; }

//responsive video

.embed { position: relative; padding-bottom: 56.25%; overflow: hidden; max-width: 100%; height: auto;

	iframe, object, embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

}
video { width: 100%; height: auto; }
.article-media--max280 .embed { width: 280px; margin-left: auto; margin-right: auto; padding-bottom: 0;

	iframe, object, embed { position: static; }

}

//audio

audio { width: 100%; display: block; }

//hide download button on chrome 55
.chrome {

	audio::-internal-media-controls-download-button { display: none; }
	audio::-webkit-media-controls-enclosure { overflow: hidden; }
	audio::-webkit-media-controls-panel { width: calc(~'100% + 30px'); margin-left: auto; }

}

//media plus

.media_plus { position: relative;

	h3 { .ossb; margin-bottom: 30px; }
	.content & h3 { .font-size(1.4); .line-height(2.5); }
	h3:empty { display: none !important; }

	ul { margin-top: 6px; height: 20px; margin-bottom: 6px; }
	li { .ibt; height: 20px; border: 1px solid #999; .gradient-v(#fff,#ddd); border-right-width: 0; cursor: pointer; }
	.content & li { margin-left: 0; padding-left: 0; }
	li:hover { background: #fff; }
	li.looping { background: #ddd; }
	li:last-child { border-right-width: 1px; }
	li span { display: none; }

	.ctrls { position: relative; }
	.position { display: block; height: 12px; width: 12px; background-color: @color_2; position: absolute; top: 4px; pointer-events: none; .rounded(6px); margin-left: -6px; }

	.buttons {

		.btn25 { .ibt; width: 24.25%; margin-right: 1%; }
		.btn25:nth-child(4n) { margin-right: 0; }

		.active a { background-position: 0 45px;

			.ico { .opacity(.33); }

		}

	}

}
.video.media_plus {

	h3 { .ibt; background-color: rgba(23, 23, 23, 0.75); color: #fff; padding: 6px 10px; .rounded(2px); position: absolute; top: 10px; left: 10px; z-index: 1; margin-bottom: 0; }

}

td span.abrsm,
td span.trinity,
td span.abrsm_and_trinity,
td span.no_board { .board_label; }

td span.abrsm { background-color: @abrsm; }
td span.trinity { background-color: @trinity; }
td span.no_board { background-color: @color_1; }
td span.abrsm_and_trinity { .gradient-h(@abrsm,@trinity); }

//blog

.blog_post .content { padding: 20px; border: 1px solid #eee; border-bottom-color: #ddd; border-right-color: #ddd;

	h1 { .font-size(2.4); .line-height(3); }
	.cat_date { margin: 5px 0; padding: 5px 0; border-top: 1px dotted #ddd; border-bottom: 1px dotted #ddd; }
	.cat_date + .cat_date { margin-top: 0; padding-top: 0; border-top: none; }

}
ul.blog_post { margin-bottom: 0;

	li { margin-bottom: 20px; }
	li:last-child { margin-bottom: 0; }

}
.cat_date { .opacity(.5); .font-size(1.2); }
.blog_list {

	.pic { display: block; width: 120px; margin-bottom: 0; }
	.pic img { .img; width: 120px; height: auto; margin-bottom: 20px; }

}
.blog_details > img { .img; max-width: 280px; }
.article-media--max280 img { .img; max-width: 280px; margin-left: auto; margin-right: auto; }

.has_rss { position: relative; padding-right: 75px; }
.rss { display: block; width: 45px; height: 45px; color: @color_2; border: 1px solid @color_2; .rounded(5px); position: absolute; top: -6px; right: 0;

	.ico:before { color: @color_2; line-height: 41px; }

}

//advert

.advert { text-align: center; width: 200px; .middle;

	hr { display: none; }
	span { .font-size(1.2); .ibt; }
	> span { text-transform: uppercase; color: #fff; background-color: @color_2; position: relative; padding: 0 10px; .rounded(5px); }
	img { display: block; margin-top: -12px; }
	a span { .osi; margin-top: 11px; .opacity(.5); }

}

//slider

.slick-slider { position: relative; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden;

	&:focus { outline: none; }
	&.dragging { cursor: pointer; cursor: hand; }

}

.slick-slider .slick-list,
.slick-track,
.slick-slide,
.slick-slide img { .transform(translate3d(0, 0, 0)); }

.slick-track { position: relative; left: 0; top: 0;

	&:before, &:after { content: ""; display: table; }
	&:after { clear: both; }

}
.slick-loading .slick-track { visibility: hidden; overflow: hidden; }

#portfolio .slick-slide { height: auto; min-height: 1px; }
.main .slick-slide,
.external .slick-slide { float: left; height: 100%; min-height: 1px; }

.slick-slide img,
.slick-initialized .slick-slide { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-loading .slick-slide { visibility: hidden; }

//modal

.modal { display: none; width: 100%; padding: 20px; pointer-events: none; position: fixed; top: 50%; left: 0;

	.m_inner { background-color: #fff; max-width: 640px; margin-left: auto; margin-right: auto; pointer-events: auto; position: relative; padding: 60px 20px; }
	.m_wide { max-width: 1004px; padding: 20px; }
	.close { width: 45px; position: absolute; top: 10px; right: 10px; margin: 0; }

	&.abs { position: absolute; margin-top: 0 !important; top: 0; }

}

.modal-spinner { display: none; width: 64px; height: 64px; position: fixed; top: 50%; left: 50%; margin-right: -32px; margin-top: -32px; }

//colorbox

#colorbox, #cboxOverlay, #cboxWrapper { position: absolute; top: 0; left: 0; z-index: 9999; overflow: hidden; }
#cboxOverlay { position: fixed; width: 100%; height: 100%; opacity: .7 !important; background-image: url("@{image_base}overlay.png") }
#cboxMiddleLeft, #cboxBottomLeft { clear: left; }
#cboxContent { position: relative; overflow: hidden; background: #fff; }
#cboxLoadedContent { overflow: auto; }
#cboxLoadedContent iframe { display: block; width: 100%; height: 100%; border: 0; }
#cboxTitle { margin: 0; }
#cboxLoadingOverlay, #cboxLoadingGraphic { position: absolute; top: 0; left: 0; width: 100%; }
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow { cursor: pointer; }

#cboxLoadedContent { margin: 20px 20px 55px 20px; }

#cboxTitle { position: absolute; bottom: 20px; left: 20px; text-align: center; font-size: 12px; }
#cboxCurrent { display: none; }
#cboxSlideshow { position: absolute; bottom: 4px; right: 30px; color: #333; }
#cboxPrevious { position: absolute; bottom: 16px; right: 78px; width: 25px; height: 25px; text-indent: -9999px; background: @color_2 url("@{image_base}prev.png") 45% 50% no-repeat; border: 0px; }
#cboxPrevious.hover { opacity: .5; }
#cboxNext { position: absolute; bottom: 16px; right: 47px; width: 25px; height: 25px; text-indent: -9999px; background: @color_2 url("@{image_base}next.png") 55% 50% no-repeat; border: 0px;  }
#cboxNext.hover { opacity: .5; }
#cboxLoadingGraphic { background-position: 50%; background-repeat: no-repeat; }
#cboxClose { position: absolute; bottom: 16px; right: 16px; width: 25px; height: 25px; text-indent: -9999px; background: @color_2 url("@{image_base}close_x.png") 50% no-repeat; border: 0px;  }
#cboxClose.hover { opacity: .5; }

//footer

.footer_container { padding: 0 30px 64px; .font-size(1.2); z-index: 1; text-align: center;

	.home_page & { padding-left: 30px; }

	.footer { padding: 160px 0 0; }
	body.admin & .footer,
	body.no_nav & .footer { padding-top: 30px; }

	.footer_links, .accepted_payments { margin-bottom: 16px; }

	.footer_links {

		li { .ibt; }
		li:after { content: "/"; .opacity(.5); margin: 0 5px; }
		li:last-child:after { display: none; }

	}

	.accepted_payments {

		li { .ibt; width: 45px; height: 45px; background-color: #ccc; background-repeat: no-repeat; background-position: 50%; .rounded(5px); margin-left: 2px; .background-size(36px,auto); }

		.visa { background-image: url("@{image_base}visa.svg"); }
		.mastercard { background-image: url("@{image_base}mastercard.svg"); }
		.jcb { background-image: url("@{image_base}jcb.svg"); }
		.maestro_card { background-image: url("@{image_base}maestro_card.svg"); }

		.no-svg & .visa { background-image: url("@{image_base}visa.png"); }
		.no-svg & .mastercard { background-image: url("@{image_base}mastercard.png"); }
		.no-svg & .jcb { background-image: url("@{image_base}jcb.png"); }
		.no-svg & .maestro_card { background-image: url("@{image_base}maestro_card.png"); }

		li:first-child { margin-left: 0; }

	}

	.copyright { .osi;

		li:first-child { .ossbi; margin-bottom: 16px; }
		span { .font_sans; }

	}

	.credits {

		li { .ibt; width: 45px; height: 45px; margin-left: 2px;  margin-top: 30px; }
		li:first-child { margin-left: 0; }

		a { display: block; border: 1px solid #ccc; .rounded(5px); color: #fff; .transition(border-color); }
		a:hover { border-color: #bbb; }
		.ico:before { line-height: 43px; color: #999; .font-size(2.4); }

		li:last-child { margin-right: 0; }

	}

}

p.credit { margin-top: 20px; opacity: .5; }

.back_top { position: fixed; z-index: 10; bottom: 0; left: 0; width: 100%; .box-shadow(0,0,10px,0,rgba(0,0,0,.1)); border-top: 1px solid #ccc;

	a { display: block; background-color: #fff; height: 44px; width: 100% }
	.ico:before { line-height: 44px; color: @color_2; .font-size(1); }

}

//very specific page fixes (via body class)

.login_page {

	.content h1 small { .ibm; margin-left: 0; }

}

//holding page adjustments

.holding_page {

	.main > .intro { background-position: 80% 0; .background-cover();

		.alert_message { max-width: 440px; padding: 20px; margin-left: auto; margin-right: auto; .transform(rotate(-2deg)); margin-bottom: 20px; .opacity(.9); position: relative;

			&:after, &:before { .ba; position: absolute; width: 20px; .gradient-v(#fff,#ccc); .opacity(.5); .box-shadow(1px,1px,1px,0,rgba(0,0,0,.1)); }
			&:after { top: -25px; left: 0px; height: 90px; .transform(rotate(20deg)); }
			&:before { bottom: -30px; right: 15px; height: 70px; .transform(rotate(-20deg)); }

		}
		.warning { .gradient-h(@color_warning,lighten(@color_warning,20%)); }

	}

}

//no media queries

.no-ie.no-mq { max-width: 280px;

	* { max-width: 280px; }

}

//detectizr fixes

.mac.webkit.desktop {

	.form_line select { margin-top: 10px; margin-bottom: 10px; }

}

//cookies

#cookies_modal { width: calc(~"100% - 20px"); background-color: rgba(255,255,255,.99); position: fixed; bottom: 10px; right: 10px; text-align: center; z-index: 999; padding: 20px; transition: bottom ease-in-out .2s; box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;

    &.shift { bottom: 55px; }

    p { margin-left: auto; margin-right: auto; max-width: 400px; }

    .btn { .ibt; width: 80px; }

}