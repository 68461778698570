.i_kevadamson:before { content: "\e000"; }
.i_music:before { content: "\e001"; }
.i_learn:before { content: "\e002"; }
.i_tags:before { content: "\e003"; }
.i_phone:before { content: "\e004"; }
.i_envelope:before { content: "\e005"; }
.i_user:before { content: "\e006"; }
.i_quotes:before { content: "\e007"; }
.i_spinner:before { content: "\e008"; }
.i_wrench:before { content: "\e009"; }
.i_cogs:before { content: "\e00a"; }
.i_stats:before { content: "\e00b"; }
.i_grid:before { content: "\e00c"; }
.i_menu:before { content: "\e00d"; }
.i_star:before { content: "\e00e"; }
.i_tick:before { content: "\e00f"; }
.i_play:before { content: "\e010"; }
.i_volume:before { content: "\e011"; }
.i_loop:before { content: "\e012"; }
.i_arrow_up:before { content: "\e013"; }
.i_arrow_right:before { content: "\e014"; }
.i_arrow_down:before { content: "\e015"; }
.i_arrow_left:before { content: "\e016"; }
.i_googleplus:before { content: "\e017"; }
.i_facebook:before { content: "\e018"; }
.i_twitter:before { content: "\e019"; }
.i_linkedin:before { content: "\e01a"; }
.i_cross:before { content: "\e01b"; }
.i_worried:before { content: "\e01c"; }
.i_pause:before { content: "\e01d"; }
.i_nosound:before { content: "\e01e"; }
.i_basket:before { content: "\e01f"; }
.i_edit:before { content: "\e020"; }
.i_show:before { content: "\e2d9"; }
.i_hide:before { content: "\e2da"; }
.i_minus:before { content: "\e375"; }
.i_plus:before { content: "\e376"; }
.i_sort:before { content: "\e425"; }
.i_search:before { content: "\e021"; }
.i_archive:before { content: "\e132"; }
.i_preview:before { content: "\e123"; }
.i_more:before { content: "\e2aa"; }
.i_school:before { content: "\e022"; }
.i_login:before { content: "\e379"; }
.i_happy:before { content: "\e320"; }
.i_sad:before { content: "\e324"; }
.i_neutral:before { content: "\e334"; }
.i_up:before { content: "\e3c1"; }
.i_down:before { content: "\e3c5"; }
.i_rss:before { content: "\e4a0"; }

.i_help:before { .ossb; content: "?"; }

.no-fontface {

	.ico:before { .font_sans; font-weight: bold; }

}