//intro

.main > .intro {

	h1 { padding-right: 240px; }
	p { padding-right: 180px; }

}

.col_2 + .search_items { padding-left: (40px + 10px); }

//guide
.test_items .guide, .guide_page .guide {

	.guide_adjust();

}
.guide_page .guide a { padding: 20px; text-align: center; }

//footer

.footer_realign;

//admin quick view

.qv_8 {

    ul:nth-child(n) { margin-right: 5%; }
    ul { width: 21.25%; }
    ul:nth-child(4n) { margin-right: 0; }
    small { display: block; margin-top: -10px; }

}